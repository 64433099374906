/* Utility */
body
{
    background-color: var(--white);
    width: 100%;
    height:100vh;
}

.hero_title
{
    font-family: var(--fontfamily);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: -6px;
}
.head_title{
    font-size: 20px;
    font-weight: 600;
    margin: 0px 0px;
    font-family: var(--fontfamily);
}
.login_title
{
    font-size: 18px;
    font-weight: 600;
    font-family: var(--fontfamily);
    line-height: normal;
}

.primary_title {
    font-size: 20px;
    font-weight: 600;
    font-family: var(--fontfamily);
    padding: 0px !important;
    margin: 0px !important;
}
.section_title
{
     font-size: 18px;
     line-height: normal;
}

.navigation_title
{
    font-size: 14px;
    font-weight: 600;
}
.book_title 
{
    font-size: 14px;
    font-weight: 500;
}
.body_title
{
    font-size:12px;
    line-height:18px !important;
}
.section
{
    margin-bottom: 40px;   
}
.section1
{
    margin-bottom: 20px;
}

.p-10
{
    padding-inline: 10px;
}
.explore-padding
{
    padding-inline: 10px; 
}

.mt-20
{
   margin-top:20px ;
}
.mb-20
{
   margin-bottom:20px ;
}
.sectiondeals
{
    padding: 20px;  
}
.sections
{
    padding: 10px 12px 20px;  
}
.tabpadding
{
    padding-inline: 10px;
}

.sectiondata{
    padding: 0px 20px;  
}

.secondary_title
{
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    font-family: var(--fontfamily);
    flex-wrap: wrap;
}
.header_title
{
    font-size: 20px;
    font-weight: 600;
    line-height: 0px;
    font-family: var(--fontfamily);
}
.explore_date
{
        font-family: var(--fontfamily);
        font-weight: 600;
}

.secondary
{
    font-size: 14px;
    font-weight: 500;
    color: #717171;
}
.page_title {
    font-size: 16px;
    color: #1a181e;
    padding-left:10%;
   
}
.page_titles{
    font-size: 34px;
    font-weight: 600;
    line-height: 44px;
    margin: 15px 0 15px;
 }
.title
{
   font-weight: 600;
     font-size: 18px;
     margin-top: -4px;
     font-family: var(--fontfamily);
}
.policy_title
{
    font-size: 16px;
    font-weight: 500;
    font-family: var(--fontfamily);
    line-height: 24px;
}
.address_title
{
    color: #000;
font-family: var(--fontfamily);
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
padding-bottom: 8px;
}
.modalitems
{
    background-color: #ffffff;
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    padding: 20px;
    border-radius: 12px 12px 0px 0px;
}


.privacy_title
{
    color: #000;
    font-family: var(--fontfamily);
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding:90px 0px 64px;
}

.primary_button {
    display: flex;
    align-items: center;
    position: relative;
    top:-2px;
    background: var(--primary);
    border: 1px solid var(--primary) !important;
    border-radius: 4px !important;
    box-sizing: border-box!important;
    letter-spacing: normal !important;
    font-family: var(--fontfamily)!important;
    font-size: 14px !important;
    text-align: center;
    cursor: pointer;
    line-height: normal;
    color: #ffffff;
    font-weight: 600;
    padding: 4px 30px!important;
}

.secondary_button{
    display: flex;
    align-items: center;
    position: relative;
    top:-2px;
    background: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 4px !important;
    box-sizing: border-box!important;
    letter-spacing: normal !important;
    font-family: var(--fontfamily)!important;
    font-size: 14px !important;
    text-align: center;
    cursor: pointer;
    line-height: normal;
    color: #ffffff;
    font-weight: 600;
    padding: 10px 24px!important;

}

 .myaccount
 {
    display: flex;
    grid-column-gap: 10px;
    padding: 10px 0px;
    border-bottom: 1px solid rgb(176, 176, 176);
    cursor: pointer;
 }

.buttons {
    display: flex;
    width: 100px;
}
/*
.primary_button:hover {
    background-color: #966de7 !important;
    -webkit-transition: background 0.8s;
    -o-transition: background 0.8s;
    transition: background 0.8s;
}*/

.cancelhover:hover
{
    background-color: #d7d5db !important;
    -webkit-transition: background 0.8s;
    -o-transition: background 0.8s;
    transition: background 0.8s;
}

/*
.secondary_button:hover {
    background-color: #d7d5db !important;
    -webkit-transition: background 0.8s;
    -o-transition: background 0.8s;
    transition: background 0.8s;
}
*/
.member
{
    color:var(--primary);
    text-decoration: none;
    text-align: center;
    position: relative;
    font-weight: 500;
}

.contain
{
    display: flex;
   flex-direction: column;
   height: 100vh;
  
}

.content
{
    flex: 1;
    overflow: auto;
    position: relative;
  
}
.title_deal_day
{
    color:black;
    font-size: 30px;
    line-height: 22px;
    font-weight: 700;
    padding-right: 10px;
}

.title_off
{ 
    color:black;
   font-size: 22px;
   font-weight: 600;
   line-height: 22px;
}

.title_price
{
    color:black;
    font-weight: 500;
    font-size: 18px;
}

.category_header
{

    background-color: var(--white);
    display: flex;
    z-index: 98;
    height: auto;
    box-sizing: border-box;
    border-bottom: 1px solid #dbdbdb;
    padding: 0px 10px;
 
}

.category_headerdata
{
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    width:100%;
    align-items: center;
    grid-row-gap: 6px;
    
}
.category_headername
{
    font-size: 14px !important;
    font-family: var(--fontfamily)!important;
    font-weight: 500 !important;
    cursor: pointer !important;
    opacity: 1 !important;
     padding: 8px 10px;
     position: relative;
     left: -8px;
}
.hamburcategory
{
    display: flex;
    align-items: center;
    grid-column-gap: 4px;
}


.category_headername:hover
 { 
    color: var(--primary); 
    background: #f5f0ff;
 }


.bookmark
{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.bookmarked
{
    display: flex;
    align-items: center;
    cursor: pointer;
}


@media (max-width:768px)
{
    .category_headername
    {
        font-size: 12px !important;
    }
    .category_header
    {
        padding: 10px 6px;
    }
    .bottom1
    {
        margin-bottom: 96px !important;
    }
    .book_title
    {
        margin: 4px 0px;
    }
    .MuiMenu-list
    {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }

    .privacy_title
    {
        color: #000;
        font-family: var(--fontfamily);
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding:90px 0px 64px;
    }
}
/* Utility */

/* Connect */

.agree{
    padding-top:10px
}

@media (min-width:768px)  and (max-width:900px) 
{
    .connect_button
    {
      
        width:32vw;
    }
    
    .section-content
    {
        display: none;
    }
    
    .connect_profile
    {
        height:260px;
        width:100%;
    }
     .connect_logo
    {
     
        padding-top: 40px;
    }
    
    .discover_title
    {
         font-size: 26px;
         font-weight: 700;
         line-height: 34px;
         padding: 30px 0px;
         text-align: center;
         width:100vw;
    }
    
    .connect_service
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding-left: 50%;
    }
}


@media (min-width:900px) 
{
    .connect_button
{
  
    width:22vw;
}

.section-content
{
    display: none;
}

.connect_profile
{
    height:280px;
    width:100%;
}
 .connect_logo
{
 
    padding-top: 40px;
}

.discover_title
{
     font-size: 26px;
     font-weight: 700;
     line-height: 34px;
     padding: 30px 0px;
     text-align: center;
     width:100vw;
}

.connect_service
{
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding-left: 50%;
}

}

@media (max-width:768px)
{
    .connect-profile
    {
       
        height:260px;
        width:100%;
        display: flex;
        justify-content: center;
        margin:0% -7%; 
        box-sizing: border-box;
        overflow-x: hidden;
        padding-top: 20px;
    } 

    .section{
        margin-bottom: 20px;
    }

    .sectiondata{
        padding: 10px 3.8% ;  
    }
    .sectiondatas{
        padding: 10px 22px ;  
    }
    .policy_title
{
    font-size: 14px;
   
}
.address_title
{
    font-size: 14px;
}
    .content
    {
        flex: 1;
        overflow: auto;
        position: relative;
        margin-bottom: 0px;
    
    }
.connect_logo
{
    display: flex;
    justify-content: center;
    transform: scale(0.8);
    padding-top: 40px;
}
.discover_title
{
     font-size: 24px;
     font-weight: 700;
     line-height: 34px;
     text-align: center;
     padding: 30px 0px;
}
.primary_connect
{
    display: flex;
    justify-content: center;

}
.connect_button
{
    width:300px;
}
.section-content
{
   padding-top: 6%;
}
.primary_title {
    font-size: 16px;
    font-weight: 550;
    font-family: var(--fontfamily);
}
.head_title{
    font-size: 16px;
    font-weight: 600;
}
 .title
    {
        font-weight: 600;
        font-size: 16px;
    }
}

@media (max-width:600px)
{
    .head_title
    {
        font-size: 16px;
        font-weight: 600;
    }
}



/* Connect */

/* Authentication Start */

.successfully
{
     left:50%;
     position: relative;
     width:300px;
     height: 50px;
     transform: translate(-50%);
     background-color: rgb(13, 247, 13);
     justify-content: center;
     display: flex;
     align-items: center;
     border-radius: 4px;
     font-weight: 600;
     margin-top: 10px;
}

.carousel .control-next.control-arrow:before {
    border-left: 0px solid var(--white) !important;    
    border-top: 0px solid transparent !important;
    border-bottom: 0px solid transparent !important;

    display: block;
    top: 50%;
    background-image: url("https://fetchbig.co.nz/assets/gallery/img/controls.png");
    cursor: pointer;
    z-index: 99;
    margin-top: -16px;
    -webkit-transition: opacity 0.35s linear 0s;
    transition: opacity 0.35s linear 0s;
    transform: rotate(180deg);
    width: 38px !important;
    height: 38px !important;
    opacity: inherit !important;
}
.carousel .control-prev.control-arrow:before {
    border-right: 0px solid var(--white) !important;    
    border-top: 0px solid transparent !important;
    border-bottom: 0px solid transparent !important;

       display: block;
    top: 50%;
    background-image: url("https://fetchbig.co.nz/assets/gallery/img/controls.png");
    cursor: pointer;
    z-index: 99;
    margin-top: -16px;
    -webkit-transition: opacity 0.35s linear 0s;
    transition: opacity 0.35s linear 0s;

    width: 38px !important;
    height: 38px !important;
    opacity: inherit !important;
    
}

.carousel.carousel-slider .control-arrow
{
    background-color: none !important;
    padding-inline:6px !important ;  
    top: 40px !important;
    color: #fff;
    font-size: 26px;
    bottom: 40px !important;
    margin-top: 0;
    padding: 5px; 
    display: flex !important;
    align-items: center !important;
}
.carousel.carousel-slider .control-arrow:hover
{
    background: none !important;
}

 .control-arrow
 {
    opacity: 1 !important;
 }

.loginheader
{
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 20px 28px;
}
.locationheader
{
    padding: 20px 28px 10px;
}
.locationbody
{
    padding: 20px 28px;
    display: grid;
    grid-row-gap: 10px;
}
.otpheader
{
    padding: 20px 28px;
}

.dragheader
{
    padding: 20px 20px 0px;
}

.dragmodal
{
    width: 700px;
    border-radius: 0px !important;
  
}
.MuiDialog-paper
{
    border-radius: 0px !important;
    max-width: 800px !important;
}
.dragbody
{
    padding: 20px 20px 0px;
    height: 80%;
}
.dragfooter
{
    margin: 10px 20px 20px;
    display: flex;
    justify-content: flex-end;
}
.loginbody
{
    padding: 20px 28px;
    display: grid;
    grid-row-gap: 16px;
}
.loginfooter
{
    padding: 20px 28px;
    display: flex;
    justify-content: flex-end;
}

.loginmobilefooter
{
    display: flex;
    justify-content: flex-end;
}
.mycurrentdata
{
    display: flex;
    grid-column-gap: 20px;
    align-items: center;
    padding: 10px 0px;
}
.current-address_data
{
    font-family: var(--fontfamily);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width:60%;
}
.signupwith
{
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.signupwithgoogle
{
    color: #878A9C;
    font-family:var(--fontfamily);
      font-size: 12px;
    font-style: normal;
      font-weight: 400;
     line-height:normal;
     margin-inline: 12px; 
}

@media (min-width:768px) and (max-width:1000px)
{
    .logincontainer
    {
        
        width:340px;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        position: absolute;
        height:  auto;
        background-color: var(--white);
        border-radius: 4px;
    }
    .left{
        flex: 1.5;
        background-color: var(--primary);
        padding: 20px;
       
        border-radius: 8px 0px 0px 8px;

    }
    .right
    {
        flex: 1;
        background-color: var(--white);
        padding: 20px;
        border-radius: 8px 8px 8px 8px;
    }
    .right_icon
    {
      
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 10px;

    }
    .closeicon
    {
        cursor: pointer;
    }

    
   
    .login_image
    {
        height:200px;

    }
    .login_photo
    {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height:400px;
        padding-bottom:20px ;
        box-sizing: border-box;
    }

      .primary_login
      {
        padding-top: 12%;
        text-transform: uppercase;
        position: relative;
        z-index: 999;
        font-weight: 600;
        font-size: 14px;
        color: var(--stroke);
        line-height: 20px;
      
      } 

      .secondary_login
      {
        padding-top: 12%;
        color:red;
        text-transform: uppercase;
        position: relative;
        z-index: 999;
        font-weight: 600;
        font-size: 14px;

      }

      .login_exist
      {
          font-size: 12px;
          color: red;
      }
      .login_data
      {
        width:100%;
        font-size: 14px;
      }
      .login_button
      {
          width:100%;
       
      }
      .lavendel_image
      {
         padding-top: 20px;
      }
    
}

@media (min-width:1000px)
{
    .logincontainer
    {
       
        width:340px;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        position: absolute;
        height:  auto;
        background-color: var(--white);
        border-radius: 4px;
    }
    .left{
        flex: 1.5;
        background-color: var(--primary);
        padding: 20px;
       
        border-radius: 8px 0px 0px 8px;

    }
    .right
    {
        flex: 2;
        background-color: var(--white);
        padding: 20px;
        border-radius: 0px 8px 8px 0px;

      
    }
    .right_icon
    {
      
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding-top: 10px;

    }
    .closeicon
    {
        cursor: pointer;
    }
    .login_image
    {
        height:200px;

    }
    .login_photo
    {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height:400px;
        padding-bottom:20px ;
        box-sizing: border-box;
    }
    .primary_login
    {
      padding-top: 12%;
      text-transform: uppercase;
      position: relative;
      z-index: 999;
      font-weight: 600;
      font-size: 14px;
      color: var(--stroke);
      line-height: 20px;
     
    }
    .secondary_login
    {
      padding-top: 12%;
      color:red;
      text-transform: uppercase;
      position: relative;
      z-index: 999;
      font-weight: 600;
      font-size: 14px;
    }
    .login_exist
    {
        font-size: 12px;
        color: red;
    }
    .login_data
    {
      width:100%;
      font-size: 14px;
    }
    .login_button
    {
        width:100%;
        
    }
    .lavendel_image
    {
       padding-top: 20px;
    }
  
}



.container
{
    padding: 0px 7%;
   
}

.login {
    background-color: var(white);
    box-sizing: border-box;
    height:auto;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    min-width: 22rem;
    padding-bottom: 30px;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    position:absolute;

}

.data{
     display: grid;
    position: relative;
}
.profile
{
    display: flex;
    box-shadow: var(--box-shadow);
    top: 0;
    z-index:999;
    align-items: center;
}
.already_exist
{
    color: red;
    font-size: 12px;
    position:absolute;
    font-weight: 500;
    top:34px;
    margin: 24px 0px;
}
.already_otp
{
    color: red;
    font-size: 12px;
    position:absolute;
    top:6px;
    font-weight: 500;
}
.field_required
{
    color: red;
    font-size: 12px;
    font-weight: 500;
    position: absolute;
    top:54px;
}
.input_data:focus{

    outline: none;
}

.hr{
    width:30vw;
    position: relative;
    top:2px;
}
.horizontal{
    display: flex;
    width:100%;
    justify-content: center;
    grid-column-gap: 10px;
    padding: 20px 0px;
}


.auth{
     display: flex;
     justify-content: center;
     grid-column-gap: 20px;
    
}
.online_authentication
{
    display: flex;
    padding: 20px 0px;
    grid-column-gap: 4%;
    justify-content: center;
    margin: -20px 10%;
}
.google
{
    
    width:100%;
}


/*
.google{
    border: 1px solid var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    background-color: var( --background-color);
    cursor: pointer;
    text-align: center;
    outline: none;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 12px 18%;
}
.facebook{
    border: 1px solid var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    background-color: var( --background-color);
    cursor: pointer;
    text-align: center;
    outline: none;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 12px 16%;
}
.signupgoogle
{
    border: 1px solid var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    background-color: var( --background-color);
    cursor: pointer;
    text-align: center;
    outline: none;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 12px 16%;
}

.signupfacebook{

    border: 1px solid var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    background-color: var( --background-color);
    cursor: pointer;
    text-align: center;
    outline: none;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 12px 16%;
}
.google
{
  transform: scale(0.75);
  margin: 0px -20%;
  
}
.facebook
{
    transform: scale(0.75);
    margin: 0px -20%;
}
*/
.terms{
   
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
}

.code {
    color:gray;
     font-size:16px;
}

.verify{
    padding: 0px 10%;
}

.sent {
    font-size: 20px;
    color:#1a181e;
    font-weight: 500;
}

.sendotp{
    display: flex;
    justify-content: flex-end;
    padding: 0% 10% 0% 0%;
 
}
.otpread
{
    padding: 0% 10%;
}
.form-control
{
    width:100% !important;
     height: 45px !important;
}


@media (max-width:1400px)
{
    .hr{
        width:3vw;
    }
}

@media (max-width:900px)
{
   

}

@media (max-width:768px)
{

   .logincontainer
    {
        width:340px;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        position: absolute;
        height:  auto;
        background-color: var(--white);
        border-radius: 4px;
    }
    .left{
        flex: 1.5;
        background-color: var(--primary);
        padding: 10px;
        border-radius: 8px 0px 0px 8px;

    }
    .right
    {
        flex: 2;
        background-color: var(--white);
        padding: 10px;
        border-radius: 0px 8px 8px 0px;
       
    }

    .form-control
    {
        width:100% !important;
         height: 43px !important;
    }
    .right_icon
    {
      
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding-top: 10px;

    }
    /*
    .closeicon
    {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        padding-bottom: 10px;
    }
    */
    .closeicon{
        cursor: pointer;
    }
    .login_image
    {
        height:100px;

    }
    .login_photo
    {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height:234px;
        padding-bottom:20px ;
        box-sizing: border-box;
    }

      .primary_login
      {
        padding-top: 12%;
        text-transform: uppercase;
        position: relative;
        z-index: 999;
        font-weight: 600;
        font-size: 14px;
        color: var(--stroke);
        line-height: 20px;
      
      } 

      .secondary_login
      {
        padding-top: 12%;
        color:red;
        text-transform: uppercase;
        position: relative;
        z-index: 999;
        font-weight: 600;
        font-size: 14px;

      }

      .login_exist
      {
          font-size: 12px;
          color: red;
      }
      .login_data
      {
        width:100%;
        font-size: 12px !important;
        margin-top: 10px;
      }
   
      .inputs_data{
        width:100%;
        font-size: 12px !important;
        position: relative;
        top:10px;
      }

      .login_button
      {
          width:100%;
      }

     
      .lavendel_image
      {
         padding-top: 20px;
         transform: scale(0.8);
      }


  
    .container
    {
        padding: 0px 2%;
    }
   
   

    
    .section_update
    {
        padding:0% 5% 0% ;
    }
    .input_data{
        line-height: 26px;
        border: none;
        border-bottom: 2px solid var(--stroke) ;
        background-color: var( --background-color);
        font-size: 12px;
     
     }
   .form
   {
       display: grid;
       grid-template-columns: 1fr 1fr;
       grid-template-rows: 1fr 1fr 1fr;
       grid-row-gap: 24px;
       grid-column-gap: 10px;
       padding-top: 40px;
   }  
   .update-profile
   {
      background-color: #e4e3e3;
      height:190px;
      width:114%;
      display: flex;
      justify-content: center;
     margin:-10% -7%; 
     box-sizing: border-box;
     overflow-x: hidden;
   }
.profile-image
{
    width:114%;
    height: 210px;
    object-fit: cover;
    padding-top: 20px;
    box-sizing: border-box;
    margin:-10% -7%; 
}
 .placeholder
     {
        display: grid;
        grid-row-gap: 5px;
        position: relative;
     }
 .placeholder1
 {
    display: grid;
    grid-row-gap: 5px;
    grid-column: span 2;
    position: relative;
 }    

 .save{
    display: grid;
    grid-row-gap: 5px;
    grid-column: span 2;
    justify-content: center;
    align-items: center;
     margin-top: 10px;
     opacity: 0.5;

     height:46px;
     border: 2px solid var(--primary) ;
     border-radius:8px;
     background-color: var(--primary);
     color:white;
     cursor: pointer;
     font-size: 20px;
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 500;
     line-height: 24px;
 }
 .save-all
 {
    display: grid;
    grid-row-gap: 5px;
    grid-column: span 2;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
     opacity: 1;
     height:46px;
     border: 2px solid var(--primary) ;
     border-radius: 8px;
     background-color: var(--primary);
     color:white;
     cursor: pointer;
     font-size: 20px;
     font-family: 'Montserrat';
     font-style: normal;
     font-weight: 500;
     line-height: 24px;
 }
 .avatar
 {
   
    padding-top: 30px;
    display: flex;
    justify-content: center;
    cursor: pointer;
 }

 .form-label
 {
       text-transform: uppercase;
        position: relative;
        font-weight: 600;
        font-size: 11px;
        color:var(--darkgray);
 }

    .keyboardback
    {
        width:26px;
        height:22px;
        transform: scale(1.2);
        padding-bottom: 20px;
    }
     .hr{
        width:100px;
        position: relative;
        left:-20px;
        margin-right: -20px;
    }
    .hr1
    {
        width:100px;
        position: relative;
        left:20px;
        margin-left: -20px;
    }

    .primary_section
    {
        padding-top: 10%;
        text-transform: uppercase;
        position: relative;
        z-index: 999;
        font-weight: 600;
        font-size: 10px;
        color: var(--stroke);
        
    }
    .secondary_section
    {
        padding-top: 10%;
        color:red;
        text-transform: uppercase;
        position: relative;
        z-index: 999;
        font-weight: 600;
        font-size: 10px;
    }
   
    .title_deal_day
    {
        font-size: 18px;
    }
    .title_off
    {
        font-size: 16px;
    }
    .title_price
    {
        font-size: 12px;
    }
    .page_title
    {
        padding-left: 5%;
    }
    .facebook
    {
        font-size: 16px;
        margin: 0% 0%;
    }
    .page_title
    {
        padding-left: 5%;
    }
   

  .primary_button
  {
    font-size: 12px !important;
  }
  .secondary_button
  {
    font-size: 12px !important;
  }
  .submission
  {
    margin-top: 10px;
  
  }
  
  .member
  {
    font-size: 14px;
  }
 
  .already_register
  {
    font-size: 14px;
    text-decoration: none;
    display: flex;
    justify-content: center;
    padding-top: 9%;
  }

  .facebook{
    padding: 6px 12%;
    margin: 0% 0%;
}

.invalid
{
  
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    box-sizing: border-box;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-shadow: var(--box-shadow);

}
.invalid-mobile
{
    padding: 10px 22%;
}

.number{
    font-weight: 500;
   font-size: 14px;
}
.icon{
    
  transform:scale(0.8)
}
.enterotp{
   display: grid;
   grid-row-gap: 10px;
   

}
.edit{
 display: flex;
 justify-content: flex-start;
 grid-column-gap: 10px;
 padding-top: 10px;
 align-items: center;
}

.otpinput
{
    display: flex;
    justify-content:center;
    width:106.5%;
}
.resend_otp
{
    color: var(--primary);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

}
.resend_otpcode
{
    color: var(--primary);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
}

.timer{
    padding-top:10px ;
    position: relative;
}
.timer_restart
{
    display: flex;
    justify-content:flex-end;
    grid-column-gap: 10px;
    padding-bottom: 18px;
}
.online_authentication
{
    margin:-20px 5%;
}


.verify{
    padding: 16px 4%;
}
 

.sendotp
{
    padding: 0% 5% 0% 0%;
}
 .sent{
    font-size: 18px;
 }
 .data{
  display: grid;
  grid-row-gap: 26px;
 }
 .valid
 {
    display: grid;
 }
 .otpinput
 {
    display: flex;
    justify-content:center;
    padding-top: 4%;
    
 }
 .otpinput input 
 {
    height: 34px !important;
    margin-right: 18px !important;
 }

 .primary_register
 {

     color:var(--stroke);
     font-size: 14px;
 }
 .otpread
 {
    padding: 0px;
 }
 .checkcircle
 {
    display: flex;
    justify-content: center;
   flex-direction: column;
   align-items: center;
    
 }
.awesome{
    top:50%;
    transform: translate(0%,-50%);
    position: absolute;

}
.checkicon
{
    transform: scale(5);
    color: var(--primary);
    position: relative;
    top:-60px;
}
.awesome_title
{
    font-size: 14px;
    color: var(--gray);
    text-align: center;
}

 .secondary_title
    {
        font-size: 14px;
        font-weight: 500;
    }
    .header_title
    {
        font-size: 16px;
        font-weight: 600;
    }

}

@media (max-width:600px)
{
    .facebook
    {
        padding: 6px 18%;
        transform: scale(1);
        margin: 0% 0%;
    }
   
    .signupgoogle
    {
        padding: 6px 21.5%;
        transform: scale(1);
        margin: 0% 0%;
    }
      
    .signupfacebook
    {
        padding: 6px 18%;
        transform: scale(1);
        margin: 0% 0%;
    }
}

@media (max-width:480px)
{
    
  

     .facebook
    {
        padding: 10px 28.5%;
        transform: scale(0.8);
        margin: 0% 0% 0% -16%;
        border: 1px solid var(--stroke);
        cursor: pointer;
    }
    

    .signupgoogle
    {
        padding: 10px 29.5% ;
        transform: scale(0.8);
        margin: 0% 0% 0% -16%;
        border: 1px solid var(--stroke);
        cursor: pointer;
        
    }
    .signupfacebook
    {
        padding: 10px 25.5%;
        transform: scale(0.8);
        margin: 0% 0% 0% -16%;
        border: 1px solid var(--stroke);
        cursor: pointer;
    }
    .login{
        min-width: 18rem;
    }
   
}


/* Authentication */

/* Verify start */
@media (min-width:768px) and (max-width:1000px)
{
 .loginverify
 {
    background-color: var(--white);
    display: flex;
    flex-wrap: wrap;
    width:340px;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    position: absolute;
    height:auto;
    flex-direction: column;

 }

 .number{
    font-weight: 500;
   font-size: 16px;
}
.number_login
{
   display: flex;
   align-items: center;
   height: 24px;
}
.login_submit
{
  width:100%;


}
.verifyotp{
    display: grid;
    grid-row-gap: 10px;
 
 }
 .verify_restart
{
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 10px;
 
}


.verify_otp
{
    color: var(--primary);
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    padding-top: 5px;

}
.verify_otpcode
{
    color: var(--primary);
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    padding-top: 5px;
}
.verifyalready_otp
{
    color: red;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    top:6px;
}


.verifyinput
{
  
    display: flex;
    justify-content:center;
    width:106%;
}
.verifyinput input 
{
   height: 34px !important;
   margin-right: 18px !important;
}


.verify_icon
{
    transform: scale(0.9);
    margin-top: 4px;
}
}

@media (min-width:1000px)
{
     .loginverify
     {
        background-color: var(--white);
        display: flex;
        flex-wrap: wrap;
        width:340px;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        position: absolute;
        height: auto;
        flex-direction: column;
     }
     .number{
        font-weight: 500;
       font-size: 16px;
    }
   .number_login
   {
      display: flex;
      align-items: center;
      height: 24px;
   }
   .login_submit
   {
     width:100%;
   }
   .verifyotp{
    display: grid;
    grid-row-gap: 10px;
 
 }
 .verify_restart
{
    display: flex;
    justify-content: flex-end;
    grid-column-gap: 10px;
  
}

.verify_otp
{
    color: var(--primary);
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    padding-top: 5px;

}
.verify_otpcode
{
    color: var(--primary);
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
    padding-top: 5px;
}
.verifyalready_otp
{
    color: red;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    top:6px;
}


.verifyinput
{
  
    display: flex;
    justify-content:center;
    width:106%;
}
.verifyinput input 
{
   height: 34px !important;
   margin-right: 18px !important;
}
.verify_icon
{
    transform: scale(0.9);
    margin-top: 4px;
    box-sizing: border-box;
}
}

@media (max-width:768px)
{
    .loginverify
    {
        background-color: var(--white);
        display: flex;
        flex-wrap: wrap;
        width:340px;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        position: absolute;
        height: auto;
        flex-direction: column;
   
    }
   
    .number{
       font-weight: 500;
      font-size: 14px;
   }
   .number_login
   {
      display: flex;
      align-items: center;
      height: 24px;
   }
   .login_submit
   {
     width:100%;
   
   
   }
   .verifyotp{
       display: grid;
       grid-row-gap: 10px;
    
    }
    .verify_restart
   {
       display: flex;
       justify-content: flex-end;
       grid-column-gap: 8px;
     
   }
 
   
   .verify_otp
   {
       color: var(--primary);
       font-size: 14px;
       font-weight: 500;
       cursor: pointer;
       padding-top: 3px;
   
   }
   .verify_otpcode
   {
       color: var(--primary);
       font-size: 14px;
       font-weight: 500;
       cursor: pointer;
       background-color: transparent;
       border: none;
       outline: none;
       padding-top: 3px;
   }
   .verifyalready_otp
   {
       color: red;
       font-size: 12px;
       font-weight: 500;
       position: relative;
       top:6px;
   }
   
   .verifyinput
   {
     
       display: flex;
       justify-content:center;
       width:106%;
   }
   .verifyinput input 
   {
      height: 34px !important;
      margin-right: 18px !important;
   }
   
   .verify_icon
   {
       transform: scale(0.9);
       margin-top: 4px;
     
   }
 
}


/* Verify End */


/* Header */

.pageError 
{
    width:300px;
}

 .header
 {
   box-shadow: -1px 4px 5px -6px #848489;
    background-color: var(--white);
    display: flex;
    align-items: center;
    z-index: 99;
    padding: 10px 10px;
    height: auto;
    box-sizing: border-box;
    
 }
 .secondary-header
 {
    box-shadow: -1px 4px 5px -6px #848489;
    background-color: var(--white);
    display: flex;
    align-items: center;
    z-index: 99;
    padding: 8px 10px;
    height: auto;
    box-sizing: border-box;
 }

 .logoImage
 {
    display: flex;
    align-items: center;
    max-width: 100%;
    min-height: 100%;
    background-color:#fff ;
 }

 .header_show
 {
    
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width:100%;
  
 }
.cityplace
{
    border: 1px solid #E8E8E8;
    background: #FAFAFA;
    border-radius: 6px;
    padding: 4px;
    display: flex;
    align-items: center;
}
 
 .city {
     border:none;
      position: relative;
      font-weight: 600;
      font-size: 14px;
      font-family: var(--fontfamily);
      background:transparent;
      cursor: pointer;
    
 }
 .city:focus{
    outline: none;
 }
 .place{
    display: flex;
    align-items: center;
    grid-column-gap: 20px;  
 }

.check_out_data { 
margin-bottom: 20px; 
border-bottom: 1px solid #d4d4d4; 
padding-bottom: 20px;
}
.check_out_data:last-child
{
    border: none;
    margin-bottom:0px !important; 
    padding-bottom:0px !important;
}

 .input_search
 {
    width:32vw;
    border: 1px solid #E8E8E8;
    border-radius: 6px;
    font-size: 16px;
    box-sizing: border-box;
    background-color: #FAFAFA;
    font-family: var(--fontfamily);
    padding: 8px 8px 8px 14px;
    
 }
 .input_search::placeholder
 {
       font-size: 14px;
       font-family: var(--fontfamily);  
       color: #A1A1A1;    
 }


.input_search:focus
{
    border: 1px solid var(--secondary);
}

.guest
{
    font-size: 14px;
    color: var(--black);
    font-weight: 500;
    padding: 6px 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.guest:hover {
    color: var(--primary);
    opacity: 0.8;
}
.cartItems
{
    background-color: red;
    color: var(--white);
    border-radius: 100%;
    padding: 2px;
    font-size: 12px;
    position: absolute;
}
.avatar
{
    position: relative;
}
.guestheader
{
    font-size: 14px;
    color: var(--black);
    font-weight: 500;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    grid-column-gap: 4px;
}

.handleLogin
{
    cursor: pointer;
    display: flex;
    align-items: center;
    grid-column-gap: 4px;
}
.handleLogins
{
    cursor: pointer;
    display: flex;
    align-items: center;
    grid-column-gap: 6px;
    font-size: 10px;
    font-weight: 500;
}
.handleLogins:hover
{
    color: var(--primary);
}
.profile_setting
{
    display: flex !important;
    grid-column-gap: 6px !important;
    align-items: center;
    
}
.chips 
{
     display: flex;
     grid-column-gap: 4px;
     color:var(--primary);
     padding: 0px 4px 0px 8px;
     margin-block:10px;
     align-items: center;
     width: fit-content;
     background-color: #e4e1eb; 
     border-radius: 12px;
     cursor: pointer;
}
.chips-closeicon
{  
    position: relative;
    top:1px;
    transform: scale(0.7);
 
}
.chips-value
{
    font-size: 14px;
    font-family: var(--fontfamily);
    font-weight: 600;
}

.openLogin
{
     cursor: pointer;
     display: flex;
     align-items: center;
}

.searchfilter
{
    padding-top:4px;
    cursor: pointer;
   position: relative;
   left: -36px;
}

.input_search_value
{
    
   display: flex;
   align-items: center;
   position: relative;
  
}

.logo{
 
    max-width:120px;
    height:30px;
    cursor: pointer;
    object-fit:contain;
}


/*
.checkoutdetails
{
    display: flex;
    grid-column-gap: 10%;
    align-items: center;
    cursor: pointer;
    color: var(--primary); 
    margin-left: 2px;
    padding-top: 3px;
}
*/
.checkoutdetails {
    grid-column-gap: 10%;
    align-items: center;
    color: var(--primary);
    cursor: pointer;
    display: flex;
    margin-top:6px;

}
.checkoutdetails td { 
    padding: 0;
     width: 36px; 
     text-align: center; 
    }
  
.deleteoutlined
{
    transform: scale(1.2);
    border-radius: 22px;
     background: #FFF;
     box-shadow: 0px 1px 12px 0px rgba(48, 47, 47, 0.08);
     padding: 4px;
    display: flex;
    align-items: center;
}

.confirmdelete
{
    background-color: var(--white);
    display: flex;
    flex-wrap: wrap;
    width:300px;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    height:auto;
    position: absolute;
    height: 240px;
    border-radius: 8px;
    flex-direction: column;
    padding:0%  3%;
    grid-row-gap: 20px;
   
}



.removeItem
{
    padding-top: 14px;
    font-size: 20px;
    font-weight: 550;
}

.removeaddress
{
    padding-top: 14px;
    text-align: center;
   font-family: var(--fontfamily);
   font-size: 18px;
   font-weight: 500;
}
.deleteservice
{
    color:lightslategrey;
}

.image_lazyload
{
   width:110px;
   height:100px; 
}
.image_lazyload img
{
   width: 100%;
   height: 100%;
   object-fit:cover;
}

.empty_cartimage
{
    width:220px;
    height:160px;
}

.empty_cartimage img{
    width: 100%;
    height: 100%;
    object-fit:fill;
}

.image_lazyloads
{
   width:68px;
   height:70px; 
}
.image_lazyloads img
{
   width: 100%;
   height: 60px;
   object-fit:cover;
   padding-top: 6px;
}


.image-uploader{
    display: flex;
    grid-column-gap: 4px;
    flex-wrap: wrap;
    grid-row-gap:4px;
}
.delete-image
{
    position: absolute;
    top:2px;
    right:1px;
}
.image_container
{
     display:flex;
     position: relative;
}


.cancelitem
{
    background-color:#FFF;
    border-radius: 6px;
    padding: 8px 0.6em;
    font-size: 18px;
    color: var(--primary);
    border:2px solid  var(--primary);
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
}
.deleteitem
{
    background-color: var(--primary);
    border-radius: 6px;
    padding: 8px 24px;
    font-size: 18px;
    color: var(--white);
    border:2px solid  var(--primary);
    outline: none;
    cursor: pointer;
}
.shopping
{
    position: relative;
    left:4px;
    display: flex;
    cursor: pointer;
}

.empty_data
{
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row-gap: 10px;
}

.shoppingBag
{
    display: flex;
    align-items: center;
    grid-column-gap: 6px;
}
.shoppingBag:hover {
    color: var(--primary);
    opacity: 0.8;
}
.opennavmenu
{
    cursor: pointer;
    display: flex;
    align-items: center;
}

.red-dot {
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
    position: absolute;
    margin-left: 10px;
  }

.deleted_item
{
     display: flex;
     justify-content: center;
     grid-column-gap: 26px;
     padding-top: 20px;
}

.coupon
{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.offerIcon
{
    display: flex;
}
.offerIcons
{
    display: flex;
    align-items: center;
    grid-column-gap: 6px;
}

.additional_save
{
     top:4px;
     position: relative;
}
.apply
{
    font-weight: 550;
    padding-left: 8px;
    font-family: var(--fontfamily);
    cursor: pointer;
}
.keyboard{
    cursor: pointer;
    color: var(--primary);
}
.localoffericon
{
     color: var(--primary);
     cursor: pointer;
}



.addcheckout {
    border-radius: 2px;
    color: var(--primary);
    cursor: pointer;
    font-size: 14px;
    padding: 4px 16px;
    background: #fff;
    border: 1px solid var(--primary);
    display: block;
    text-align: center;
}
.addcheckout:hover {
    background: #f5f0ff;
    -webkit-transition: background 0.8s;
    -o-transition: background 0.8s;
    transition: background 0.8s;
}
.add_data
{
    display: flex;
    width:100%;
    justify-content: flex-start;
    margin-top: 0.5em;
    
}

.react-multiple-carousel__arrow--right {
    right: 0px !important;
}
.react-multiple-carousel__arrow--left {
    left:0px !important;
}


.react-multiple-carousel__arrow
{
    background-color: var(--white) !important;
    min-width:28px !important;
    min-height: 28px !important;
    border: 1px solid rgba(134, 134, 134, 0.25) !important;
    z-index: 9 !important;
   
}

.slick-prev
{
   
   background-image: url("https://fetchbig.co.nz/assets/gallery/img/controls.png") !important;
   cursor: pointer !important;
   z-index: 9 !important;
   -webkit-transition: opacity 0.35s linear 0s !important;
   transition: opacity 0.35s linear 0s !important;
   left: -10px !important;
   width: 38px !important;
   height: 38px !important;
   opacity: inherit !important;
   background-color: none !important;
   outline: none !important;
   display: flex !important;
   align-items: center !important;
   justify-content: center !important;
   
}
 .slick-next 
{
    
    background-image: url("https://fetchbig.co.nz/assets/gallery/img/controls.png") !important;
    cursor: pointer !important;
    z-index: 9 !important;
    -webkit-transition: opacity 0.35s linear 0s !important;
    transition: opacity 0.35s linear 0s !important;
    right: -10px !important;
    width: 38px !important;
    height: 38px !important;
    opacity: inherit !important;
    background-color: none !important;
    outline: none !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: absolute !important;
    top: 50% !important;
    transform: translate(0%, -50%) rotate(180deg) !important;
}

.slick-prev:before, .slick-next:before

{
  
    background-color: none !important;
    outline: none !important;
    font-size: 0 !important;   
}

.react-multi-carousel-list
{
    position:none !important;
}
.react-multiple-carousel__arrow::before
{
    color: var(--black) !important;
}

.bill_details
{
     font-weight: 550;
     font-family: var(--fontfamily);
}
.item_booking
{
    
    display: grid;
    grid-row-gap: 10px;
    box-sizing: border-box;
}

.item_bookingdata
{
    
    display: grid;
    grid-row-gap: 10px;
    padding:4px 16px 14px;
    box-sizing: border-box;
}

@media (max-width:768px) and  (min-width:600px)
{
       .searchoutlined
       {
          position: relative;
          top:2px;
          left: -8px;
          
       }
}

@media (max-width:767px)
{
     .input_search
     {
        display: none;
     }
     .pageError
     {
        width: 240px;
     }
   
     .header_show
     {
       
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        width:100%;
        align-items: center;
     }
     
     .logo{
        max-width:80px;
        height:30px;
        cursor: pointer;
       object-fit:contain;
    
     }
     .place
     {
        position: relative;
        left:-1%;
     }
   
    

     .mobile-headershow
     {
        display: flex;
        grid-column-gap: 4px;
     }
    
   .container-padding
   {
       padding-inline: 10px !important;
       margin-bottom: 10px;
   }
   .explore-padding
   {
       padding-inline: 0px; 
   }
     .red-dot
     {
        width: 8px;
        height: 8px;
        background-color: red;
        border-radius: 50%;
        position: absolute;
        margin-left: 10px;
     }


 .image_lazyload
 {
    width:96px;
    height:90px; 
 }

.image_lazyload img
{
    width: 100%;
    height:100%;
    object-fit: cover;  
}

.image_lazyloads
{
    width:68px;
    height:70px; 
}
.image_lazyloads img
{
   width: 100%;
   height: 60px;
   object-fit:cover;
}
.checkoutdetails
{
    padding-top: 0px;
    display: flex;
    justify-content: space-between;
}


}

@media (max-width:600px)
{
    .place
    {
       position: relative;
       left:-10px;
       
    }
    .searchoutlined
    {
       position: relative;
       top:2px;
       left: -8px;
       
    }
}
@media (min-width:768px)
{
    .openLogin
    {
        display: none;
    }
    .searchoutlined
    {
        display: none;
    }
    .guestheader 
    {
        display: none;
    }
   
  .placeIcon
  {
     display: none;
  }

}


/* Header */
/*Profile */
.avatar_profile
{
    display: flex;
    align-items: center;
    grid-column-gap: 8%;
    
}
.avatar_photo
{
    transform: scale(1.2);
    margin: 4px;
}
.hello{
   font-size: 18px;
   font-weight: 500;
   font-family: var(--fontfamily);
}
.personal
{
   display: flex;
   grid-column-gap: 4%;
}   
.update_photo
{

    display:none;
}

.gender
{
    display: flex;
}

.edit_data
{
    display: flex;
    justify-content: space-between;
}
.edit{
    cursor: pointer;
    text-decoration: underline;
    color: var(--primary);
    font-weight: 600;
}
.save_profile
{
    display: flex;
    justify-content: flex-end;
  
}


.text_field
{
    width:100%;
}

.field_data
{
    width:50%;
}
.updateprofile
{
    display: flex;
    flex-wrap: wrap;
    width:44%;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    height:auto;
    position: absolute;
    height: 260px;
    border-radius: 12px;
    justify-content: center;
    padding-top: 10px;
    box-sizing: border-box;
    text-align: center;
    background-color: var(--white);
}

.completeprofile
{
    
    width: 44%;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    height:auto;
    position: absolute;
    border-radius: 12px;
    justify-content: center;
    padding-top: 10px;
    
}
.variantDetails
{
    font-size: 12px;
    color:#2d2d2d !important;
}

.change_profile
{
   font-size: 20px;
   font-weight: 500;
   height:50px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
}

.hr_profile
{
    width:100%;
   
}
.upload_photo
{
   color:var(--primary) ;
   font-size: 16px;
   font-weight: 500;
   height:30px;
   display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.remove_photo
{
     color:red;
     font-size: 16px;
     font-weight: 500;
     height:30px;
     display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.cancel
{
    height:30px;
    font-weight: 500;
    display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
}

.label
{
    font-size:16px;
    font-family: var(--fontfamily);
    font-weight: 500;
}

.hrdata
{
    
     padding-top: 18px;
}
.details
{
    color:var(--stroke);
}

.validemail
{
    font-family: var(--fontfamily);
    color: red;
    font-weight: 500;
}
.personalinfo
{
    font-size: 22px;
    font-weight: 600;
    font-family: var(--fontfamily);
}

@media (max-width:768px)
{
    .personalinfo
    {
        font-size: 18px;
    }

    .label{
        font-size: 14px;
    }
    .save{
        font-size: 16px;
   }
    .hello{
    font-size: 16px;
   }
   .edit
   {
    font-size: 12px;
   }
   .details
   {
    font-size: 13px;
   }


}

/*Profile */


/* Deals */


.bathroom{

    width:100%;
    object-fit:cover;
    border-radius: 10px;
    height: 100%;
  
}

.react-multi-carousel-track 
{
     grid-column-gap: 0px !important;
}
.bath_deal
{ 
    height:280px;
    width:100%;
    cursor: pointer;
}
.viewsimilarserviced
{
    margin-inline: 12px; 
}
.deals li
{
    margin-left: 8px;
}
.deals li:first-child
{
    margin-left: 0px;
}

.sectioncontainer
{
    padding-left: 12px !important;
    padding-right: 12px !important;
}
.viewsectioncontainer
{
    padding-left: 12px !important;
    padding-right: 12px !important;
}

@media (max-width:700px)
{
    .bathroom
    {
        width:100%;
    }

}
@media (max-width:768px)
{
  .deals li
  {
     margin-left: 10px;
  }
 .bath_deal
  {
    height:auto;
    width:100%;
    aspect-ratio: 2;
  }

  .slider-header
  {
    margin: 10px 0px 20px;
  }
}


/* Deals */

/* Category */


.global_box_explore {
 
    
      cursor: pointer;
      background-color: white;
      flex: 1;
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      margin-top: 10px;
      box-sizing: border-box;
      aspect-ratio: 1.50;
      width: 100%;
      height:100%;
      
  }
  .category_name img 
  { 
      aspect-ratio: 1.25;
      object-fit:contain;
      border-radius: 8px 8px 0px 0px ;
      width: 100%;
      height:100%;
  }
  .category_name
{
    width:100%;
    border-radius: 8px;
   border: 1px solid lightgray;
   background: #FAFAFA;
}


  
  /* Add more styles as needed */
  
  /*
  .category_name img {
    max-width: 100%; 
    max-height: 100%; 
    position: absolute;
    top: 0;
    left: 0;
  }
  
 */
  
 a:hover {
    color: var(--primary);
    text-decoration: underline;
    text-underline-position: under;
}


.explore_image img{
       
    height:100%;
    width:100%;
    object-fit: cover;
}
.skeleton
{
    height:146px;
    position: relative;
    top:-3px;
   
}

.skeletonname
{
   height:8vh;
}
.by_category
{
     padding-top: 20px;
}
.category_wise_datas
{
 padding: 0px 10px 0px 3%;

}
.categoriesdata
{
    padding: 6% 0% 6%;
    height: 84%;
  
}

.similar_breadservices
{
    padding-inline:12px;
}

@media (max-width:1000px) and (min-width:768px)
{
    


    .text-wrapping
    {
        
        color: var(--black);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width:98%;
        text-align: start;
         
    }
    .text-wrap
    {
      
        line-height: 20px;
        margin: 2px 0 ;
        white-space: nowrap;
        color: var(--black);
        text-overflow: ellipsis;
        overflow: hidden;
        width: 16vw;
        text-align: start;   
    }
    .text-wrapped
    {
        line-height: 20px;
        margin: 0 0 6px;
        color: var(--black);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width:75%;
        text-align: start;
         
    }

    .explore_container
    {
        padding-top: 10px;
    }
   
    .explore_categoryname
    {
      
        font-family: var(--fontfamily);
        font-size: 14px;
        font-weight: 500;
         position: relative;
         display: flex;
         align-items: center;
         justify-content: center;
          padding: 6px;  
          text-align: center;
         flex: 1;

    }

    .explore_categorydata
    {
        height:76px;
    }
    .bottom_container {
        display: grid;
        padding: 14px;
        grid-row-gap: 10px;
    }
    .category_wise_data
    {
     padding: 10px;
     display: grid;
    }
 
}

@media (min-width:1000px)
{
    .text-wrapping
    {
       
      
        color: var(--black);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width:98%;
        text-align: start; 
    }
    .text-wrapped
    {
        line-height: 20px;
        margin: 0 0 6px;
        color: var(--black);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width:75%;
        text-align: start;
        position: relative;
         
    }
    
    .text-wrap
    {
       
        line-height: 20px;
        margin: 2px 0 ;
        color: var(--black);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 16.4vw;
        text-align: start;
      
    }
    
    .explore_container
    {
        padding-top: 10px;
    }
    .explore_categoryname
    {
       
     font-family: var(--fontfamily);
    font-size: 14px;
    font-weight: 500;
     position: relative;
     display: flex;
     align-items: center;
     justify-content: center;
      padding: 6px;
      text-align: center; 
      flex: 1; 

    }

    .explore_categorydata
    {
        height:80px;
    }
   .bottom_container
   {
      padding: 14px;
      display: grid;
      grid-row-gap: 10px;
   }
   .category_wise_data
   {
    padding: 10px;
    display: grid;
   }
}

@media (min-width:768px)
{
    .explore{
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
      
    }

    
 
    .skeletoncategoriesdata
    {
        padding: 6% 0% 0%;
    }

    .explore_smallname
    {
        display: none;
    }
    

}

.explore_container
{
    width:100%;
}

.explore_design
{
    width:90%;
    height: auto;
    box-shadow: var(--box-shadow);
    padding: 2% 0%;
    border-radius: 6px;
    cursor: pointer;

}
.image_category
{
    width:100%;
    height:100%;
    padding: 8px 4px;
    box-sizing: border-box;
    position: absolute;
   

}


.categoriesitem
{
    display: none; 
}

@media (max-width:768px)
{
    .explore
    {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2% 0%;
        box-sizing: border-box;
    }
    .address_selections
    {
        display: grid;
        grid-row-gap: 4px;
       padding: 2px 0px;
    }


    .categoriesdata
    {
        padding: 0%;
        height: 90%;
    }
  .similar_breadservices
  {
    margin-inline: 0px;
  }

    .category_wise_datas
{
 padding: 0px 10px 0px 10px;

}
    .categoriesitem
    {
        display: flex;
        flex-direction: column;
    }
   
    .explore_smallname
    {
      
           
            font-size: 10px;
            font-weight: 500;
            text-align: center;
            font-family:var(--fontfamily);
            display: flex;
            padding-top: 10px;
            align-items: center;
            justify-content: center;
        
    }
    
    .explore_image img{
       
        height:100%;
        width:100%;
        object-fit: cover;
    }

     .text-wrapping
    {
       
   
        color: var(--black);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width:98%;
        text-align: start;
     
    }
    .text-wrapped
    {
         white-space: nowrap; 
          width: 75%; 
         overflow: hidden;
         text-overflow: ellipsis; 
         
    }
  
    .text-wrap
    {
       
      
        line-height: 20px;
        margin: 2px 0 ;
        color: var(--black);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width:130px;
        text-align: start;
    }

    .explore_categoryname
    {
           
       
        font-family: var(--fontfamily);
        font-size: 12px;
        font-weight: 500;
         position: relative;
         display: flex;
         align-items: center;
         justify-content: center;
          padding:6px;
          text-align: center;
        flex: 1;
    }

    .explore_categorydata
    {
        height:76px;
    }

    .skeleton
    {
        height: 100px;
    }
    .bottom_container
    {
        display: grid;
        grid-row-gap: 10px;
        padding: 14px;
    }

    .category_wise_data
    {
        padding: 10px;
    }

}

@media (max-width:600px)
{
    .explore
    {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2% 0%;
        box-sizing: border-box;
      
    }
    .explore_categorydata
    {
        height:74px;
    }

    .explore_categoryname
    {
       
        font-family: var(--fontfamily);
        font-size: 12px;
        font-weight: 500;
         position: relative;
         display: flex;
         align-items: center;
         justify-content: center;
          padding: 6px;
          text-align: center;
        flex: 1;
    }


 .viewsimilarserviced 
{
    margin-inline: 10px; 
}


    .sectioncontainer
    {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .viewsectioncontainer
    {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .sectiondeals
    {
          margin: 20px 0px 4px 0px;
    }
  
}

@media (max-width:360px)
{
    .explore_categoryname
    {
        font-size: 10px;
    }
}

/* Category */

/* Cleaning */

.view_all
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
}
.view-all
{
    cursor:  pointer !important;
    font-size: 14px;
    text-decoration: none;
    font-weight: 600;
    line-height: 18px;
    color: var(--primary);
}

.view-all:hover
{
    color:#946ce3;
    text-decoration: underline;
}
.variantdata
{
     display:grid;
     grid-row-gap: 16px;
}
.selection
{
   display: grid;
   grid-row-gap: 6px;
   position: relative;
}

.select
{
    color: #000;
    font-family: var(--fontfamily);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; 
    padding-bottom: 4px;
}
.selectoffer
{
    color: #000;
    font-family: var(--fontfamily);
    font-size: 12px;
    font-style: normal;
    font-weight: 550;
    line-height: normal; 
}
.sold{

    color: #E73C3C;
    font-weight: 600;
}

.product_select
{
    border:1px solid #434343;
    background: #FFF;
    padding: 10px 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    font-family: var(--fontfamily);
    width: fit-content;
    border-radius: 4px;
    cursor: pointer;
}


@media (max-width:768px)
{

    .select
{
    color: #000;
    font-family: var(--fontfamily);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

}
.product_select
{
    border: 1px solid #434343;
    background: #FFF;
    padding: 8px 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    font-family: var(--fontfamily);
}
.variantdata
{
     display:grid;
     grid-row-gap: 10px;
}


.selection 
{
    grid-row-gap: 6px;
}
    .picks
    {
        font-size: 22px;
    }
    .recommend 
    {
        font-size: 15px;
        font-weight: 700;
    }
    .nav-bar
    {
        font-size: 16px;
        color: #000000;
        font-weight: 500;
    }

    .secondary{
         font-size: 12px;
         font-weight: 500;
         font-family: var(--fontfamily);
         color:#717171
    }


    
.cleaning
{
    background-color: var(--white);
    height:auto;
    padding: 0% 0% 5% 0%;
    box-shadow: var(--box-shadow);
    border-radius: 6px;
    cursor: pointer;
    
}

.recommendedhr
{
    margin-left: -5%;
    color: grey;
    opacity: 0.2;
    position: relative;
    top:-14px;

}
.service
{
     width:100%;
     height: 80px;
     object-fit: contain; 
     border-radius: 8px;
     margin-top: -19px;
   
}
.service_name
{
    width:100%;
    font-size: 11px;
    font-weight: 500;
}

.rating
{
    color: var(--stroke);
    display: flex;
    align-items: center;
    font-size: 8px;
    left:-4px;
    position: relative;
}
.ratingtime
{
    color: var(--stroke);
    display: flex;
    align-items: center;
    font-size: 8px;
    left:-4px;
    position: relative;
    top:-8px;
}
.discount
{
    color: green;
    font-weight: 600;
    position: relative;
    font-size: 14px;
}

.account_circle
{
    display: flex;
    align-items: center;
    left:-4px;
    font-size: 8px;
    position: relative;
}

.real_price
{
    color: gray;
    font-size: 14px;
    position: relative;
    top:-2px;
}
.price{
    display: flex;
    align-items: center;
    position: relative;
}

.currency
{
    position: relative;
    left:-4px;
    top:1px;
}
.currency1
{
    position: relative;
    left:-3px;
   
}
.expected_price
{
    display: flex;
    align-items: center;
    position: relative;
    font-size: 14px;
    top:-2px;
    font-weight: 600;
    line-height: 22px;
}

.product_expected_price
{
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
    font-family: var(--fontfamily);
    color: #17161b;
}
.product_real_price
{
    
    color: #9B9A9D;
    font-family:var(--fontfamily);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
}
.all_taxes
{
    color: #615F5F;
    font-family: var(--fontfamily);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.discount_price
{
    display: flex;
    font-size:9px;
    font-weight: 500;
    color: white;
    border-radius: 3px;
    background: #FF7575;
    padding: 2px;
    align-items: center;
    position: relative;
    top:-2px;
}
.product_discount_price
{
    color: #E73C3C;
    font-family: var(--fontfamily);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.discounted_price
{
    display: flex;
    font-size: 8px;
    font-weight: 500;
    color: white;
    border-radius: 3px;
    background: #FF7575;
    padding: 2px;
    align-items: center;
    position: absolute;
    top:10px;
    right: 10px;
}

.real_price{

    display: flex;
    align-items: center;
    position: relative;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #9B9A9D;
}
.picks
{
   position: relative;
   top:20px;
   padding-left: 6%;
   font-weight: 500;
   font-size: 24px;
}


.cleaning_service
{
    text-decoration: none;
    color: black;
    padding-left:5% ;
}

}

/* Cleaning */


/* Picks */

.explore_category
{
    display:grid;
}
.explore_image_category
{
    height: 150px;
    width:auto;
   
}
.explore_categorydatas
{
    display: grid;
    grid-template-columns: 80px auto;
    grid-template-rows: 1fr;
}
.explore_image_categorydatas img
{
    height:10vh;
    width:80px;   
}
.imgdata{
    height: 11vh;
}

.explore_image_category img{
     
     width:100%;
     object-fit:fill;
     height: 100%;
     border-radius: 12px 12px 0px 0px; 
}
.explore_image_recommend
{  
     height:150px;
     width:auto;
}
.explore_image_recommend img
{
  
   height:100%;
   width:100%;
   object-fit: contain; 
   background-color:#fafafa;
   border-radius: 3.5px 3.5px 0px 0px;
}
.transform_data:hover
{
    transform: scale(1.02);
    box-shadow: 0 4px 6px 4px rgba(0,0,0,.08) !important;
}

.pagination
{
    display: flex;
    justify-content: end;
    margin:10px 0px 20px;
    width:100%;
}

.explore_image_similar
{
    height:140px;
    width:auto;
    background-color: #b6b4b4;
}
.explore_image_similar img
{
  
   height:100%;
   width:100%;
   aspect-ratio: 1;
   object-fit: contain; 
   border-radius: 12px 12px 0px 0px;  
}


.view_detailsIcon
{
    display: flex;
    color: var(--primary);
    align-items: center;
    margin-top: 8px;
    cursor: pointer;
}
.view_details
{
   
    padding: 0;
    background: none;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: var(--primary);
}
.expandCircle
{
  position: relative;
  top:2px;
   transform: rotate(-90deg) scale(0.8);
}

.categoriespickdatas
{
    padding:12px 12px 4px;
}

.currencydata
{
    position: relative;
    left:-6px;
}


.priceIconsimilar
{
     display: flex;
     grid-column-gap:4px;
}
.expected_pricesimilar
{
    display: flex;
    align-items: center;
    position: relative;
    font-size: 20px;
    font-weight: 600;
   
    
}
.total_pricesimilar
{
    display: flex;
    position: relative;
    grid-column-gap: 8px;
    box-sizing: border-box;
    margin-left: 3px;
    align-items: center;
    padding-top: 6px;
}

.discountsimilar
{
    color: green;
    font-weight: 600;
    position: relative;
    font-size: 14px;
}
.real_pricesimilar
{
    color: gray;
    font-size: 16px;
    position: relative;
    top:-2px;
}

@media (max-width:1000px) and (min-width:768px)
{
   
.discount
{
    color: green;
    font-weight: 600;
    position: relative;
    font-size: 14px;
}
.real_price
{
    color: gray;
    font-size: 14px;
    position: relative;
}
.price{
    display: flex;
    align-items: center;
    position: relative;
}

.currency
{
    position: relative;
    left:-4px;
}

.rupee
{
    transform: scale(0.6);
}
.rupeecurrency
{
    transform: scale(0.9);
}

.expected_price
{
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
}
.real_price{

    display: flex;
    align-items: center;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #9B9A9D;
}
.discount_price 
{
    display: flex;
    font-size: 10px;
    font-weight: 500;
    color: white;
    border-radius: 3px;
    background: #FF7575;
    padding: 4px;
    align-items: center;
}
.product_discount_price
{
    color: #E73C3C;
    font-family: var(--fontfamily);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.product_expected_price
{
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
    font-family: var(--fontfamily);
    color: #17161b;
}
.product_real_price
{
    
    color: #9B9A9D;
    font-family:var(--fontfamily);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
}
.all_taxes
{
    color: #615F5F;
    font-family: var(--fontfamily);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    top:-8px;
}

.discounted_price
{
    display: flex;
    font-size: 10px;
    font-weight: 500;
    color: white;
    border-radius: 3px;
    background: #FF7575;
    padding: 2px;
    align-items: center;
    position: absolute;
    top:10px;
    right: 10px;
}



.priceIcon
{
   
    display: flex;
    grid-column-gap: 6px;
    width:100%;
    padding-bottom: 6px;
    align-items: center;
}
.priceIcondata
{
    display: flex;
    justify-content: center;
    position: relative;
    grid-column-gap: 4%;
    top:2px;
    width:103%;
    margin-top:-4px ;
    margin-bottom: 10px;
}
.priceIconSimilar
{
    display: flex;
    position: relative;
    grid-column-gap: 4%;
    top:2px;
    width:100%;
    padding:0px 0px 8px 0px;
}
.pickrating
{
    color: var(--stroke);
    display: flex;
    align-items: center;
    position: relative;
     font-size: 12px;
    line-height: 13px;
   
}
.starIcon
{
    display: flex;
    grid-column-gap: 2%;
    margin-left: -5px;
    align-items: center;  
    position: relative;
    top:-2px;
}

.stardata
{
    display: grid;
    margin-top: 2px;
}


.view_detailsIcon
{
    margin-top: -2px;
}

.accessrating
{
    padding: 0px 0px 0px 2px;
    margin-left: -4px;
}
.accessrating_data 
{
    margin-left: -4px;
}
}



@media (min-width:1000px)
{
    .priceIcon
    {
        
        display: flex;
        grid-column-gap: 6px;
        padding-bottom: 6px;
        align-items: center;
        
    }
    .priceIcondata
    {
        display: flex;
        justify-content: center;
        position: relative;
        top:3px;
        grid-column-gap: 4%;
        width:103%;
        margin-top:-4px ;
        margin-bottom: 10px;
    }
    .priceIconSimilar
{
    display: flex;
    position: relative;
    grid-column-gap: 4%;
    top:3px;
    width:103%;
    padding:0px 0px 8px 0px;
}
    .starIcon
    {
        display: flex;
        grid-column-gap: 2%;
        margin-left: -5px;
         align-items: center;
         position: relative;
         top:-2px;
    }

    .stardata
    {
        display: grid;
        grid-row-gap: 4px;
        margin-top: 2px;
    }
    .accessrating
{
    padding: 0px 0px 0px 2px;
    margin-left: -3px;
}
.accessrating_data 
{
    margin-left: -3px;
}

    .discount
{
    color: green;
    font-weight: 600;
    position: relative;
    font-size: 14px;
}
.real_price
{
    color: gray;
    font-size: 16px;
    position: relative;
}
.price{
    display: flex;
    align-items: center;
    position: relative;
}

.currency
{
    position: relative;
    left:-4px;
}
.currency1{
    position: relative;

}
.expected_price
{
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
}

.real_price{

    display: flex;
    align-items: center;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: #9B9A9D;
}
.discount_price 
{
    display: flex;
    font-size: 10px;
    font-weight: 500;
    color: white;
    border-radius: 3px;
    background: #FF7575;
    padding: 4px;
    align-items: center;
}
.product_discount_price
{
    color: #E73C3C;
    font-family: var(--fontfamily);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.product_expected_price
{
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
    font-family: var(--fontfamily);
    color: #17161b;
}
.product_real_price
{
    
    color: #9B9A9D;
    font-family:var(--fontfamily);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
}
.all_taxes
{
    color: #615F5F;
    font-family: var(--fontfamily);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    top:-8px;
}

.discounted_price
{
    display: flex;
    font-size: 10px;
    font-weight: 500;
    color: white;
    border-radius: 3px;
    background: #FF7575;
    padding: 2px;
    align-items: center;
    position: absolute;
    top:14px;
    right: 12px;
}

  .pickrating
{
    color: var(--stroke);
    display: flex;
    align-items: center;
     font-size: 12px;
    line-height: 13px;
}
.rupee
{
    transform: scale(0.6);
}

.rupeecurrency
{
    transform: scale(0.9);
}  



.categoriespickdata
{
    padding:2% 2% ;
}



}




@media (max-width:768px)
{
    .starhr
    {
        width:76vw;
        color: grey;
        opacity: 0.2;
        margin-top: 0px;
        position: relative;
        top:-2px;
        box-sizing: border-box;
    }
   
    .starIcon
    {
        display: flex;
        grid-column-gap: 2%;
        margin-left: -6px;
         align-items: center;
         position: relative;
         top:-2px;
    }
    
    .stardata
    {
        display: grid;
        padding: 2px 0px 0px 0px;
        grid-row-gap: 0px;
        margin-top: -4px;
    }
    .expected_pricesimilar
    {
        font-size: 16px;
    }
    .discountsimilar
    {
        font-size: 10px;
    }
    .real_pricesimilar
    {
        font-size: 12px;
    }

    .accessrating
{
    padding: 0px 0px 0px 1px;
    margin-left: -4px;
}
.accessrating_data 
{
    margin-left: -4px;
}
    
    .servicestarIcon
    {
        display: flex;
        grid-column-gap: 2%;
        width:100%;
        box-sizing: border-box;
    }
    .pickrating
    {
        color: var(--stroke);
        display: flex;
        align-items: center;
         font-size: 12px;
    line-height: 13px;
    }
    
    .picks_cleaning
    {
        display: flex;
        background-color: var(--white);
        height:auto;
        padding:3%;
        box-shadow: var(--box-shadow);
        border-radius: 6px;
        cursor: pointer;
        box-sizing: border-box;
        margin-top: -3%;
    }
    .pick_service
    {
        width:100%;
        height:60px;
        object-fit: cover;
        border-radius: 3px;  
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }
    .picks_image
    {
        width:22%;
        box-sizing: border-box;
    }
    
    .priceIcon
    {
 
           
    display: flex;
    grid-column-gap: 6px;
    position: relative;
    top: 2px;
    align-items: center;

    }

    .view_detailsIcon
    {
        margin-top: -3px;
    }
    .view_details{
        font-size: 12px;
        line-height: 18px;
    }
    .expandCircle
    {
        transform: rotate(-90deg) scale(0.7);
        position: relative;
        top:2px;
    }

    .priceIcondata
    {
        display: flex;
        justify-content: center;
        position: relative;
        grid-column-gap: 4%;
        margin-top:-4px ;
        width:103%;
       margin-bottom: 10px;
    }
    .priceIconSimilar
{
    display: flex;
    position: relative;
    grid-column-gap: 4%;
    top:2px;
    width:100%;
    padding:0px 0px 8px 0px;
}

    .rupee
    {
        transform: scale(0.6);
    }
    .rupeecurrency
    {
        transform: scale(0.8);
    }
    .rupee_data
  {
    transform: scale(0.8); 
  } 
    .servicepriceIcon
    {
        display: flex;
        margin:-10% 0% -6% ;
        width:100%;
        justify-content: center;
        text-align: center;
    }

    .explore_image_category
    {
        height:100px;
        width:auto;
    }
    .explore_image_category img
    {
         height:100%;
         width:100%;
         object-fit: fill;
    }
    .explore_image_recommend
    {
         height:100px;
         width:auto;
    }
    .explore_image_recommend img 
    {
        height:100%;
        width:100%;
        object-fit: contain;
        background-color:#fafafa;
        border-radius: 3.5px 3.5px 0px 0px;

    }
    .explore_image_similar
    {
        height:120px;
        width:auto;
        background-color: #b6b4b4;
    }
    .explore_image_similar img
    {
      
       height:100%;
       width:100%;
       aspect-ratio: 1;
       object-fit: contain; 
       border-radius: 12px 12px 0px 0px;  
    }
    .total_pricesimilar
    {
        padding-top: 9px;
    }
}

@media (max-width:600px)
{
    .explore_category
    {
        display: grid;
        grid-template-columns: 80px auto;
        grid-template-rows: 1fr;
    }
    .explore_image_category
    {
        height:14vh;
        width:80px;
        
    }

  


    .explore_image_recommend
    {
         height:100px;
         width:auto;
    }
    .explore_image_recommend img 
    {
        height:100%;
        width:100%;
        object-fit: contain;
        background-color:#fafafa;
        border-radius: 3.5px 3.5px 0px 0px;
    }
    .explore_image_similar
    {
        height:100px;
        width:auto;
        background-color: #b6b4b4;
    }
    .explore_image_similar img
    {
      
       height:100%;
       width:100%;
       aspect-ratio: 1;
       object-fit: contain; 
       border-radius: 12px 12px 0px 0px;  
    }

    .explore_image_category img
    {
        height:100%;
        width:100%;
        object-fit: fill;
        border-radius: 4px;
    }

    .categoriespicks
    {
        padding:2% 2% 0.4% ;
    }
  

    .text-wrapping
    {
       
      white-space: nowrap;
       overflow: hidden;
      text-overflow: ellipsis; 
       width:98%;
      color:var(--black);
    }
    .text-wrap
    {
     
       white-space: nowrap;
       overflow: hidden;
      text-overflow: ellipsis; 
       width:55vw;
       color:var(--black);
    }

    .bottom_container
    {
       padding: 10px;
    }

    .category_wise_data
    {
        padding: 0px 0px 0px 10px;
    }

    .explore_title_padding
    {
        padding: 18px 0px 0px 18px;
    }
   
    .nav-bar
    {
        font-size: 12px;
        color: #000000;
        font-weight: 500;
    }
     .priceIcon
    {

       position: relative;
       top:2px;
       align-items: center;
      
    }

    .priceIcondata
    {
        display: flex;
        position: relative;
        grid-column-gap: 4%;
        justify-content: flex-start;
        width:103%;
        padding:0px 0px 0px 12px;
        margin-top:-6px ;
    }
    .priceIconSimilar
{
        display: flex;
        position: relative;
        grid-column-gap: 2%;
        justify-content: flex-start;
        width:100%;
        padding:4px 0px 0px 0px;
        margin-left: -6px;    
}


    .starIcon
    {
        display: flex;
        grid-column-gap: 0%;
        margin-left: -6px;
         align-items: center;
       
    }
    
    .stardata
    {
        width:60vw;
        display: flex;
        grid-column-gap: 10px;
        box-sizing: border-box;
        margin-top: -6px;
    }
    .accessrating
{
    padding: 0px 0px 0px 1px;
    position: relative;
    top:-2px;
}
.accessrating_data 
{
    margin-left: 0px;
}
}

@media (max-width:360px)
{
    .pickrating
    {
        font-size: 10px;
        line-height: 13px;
    }

   .discount
   {
    font-size: 12px;
    position: relative;
    top:3px;
    font-weight: 600;
   }
 
 
   .rupee
   {
     transform: scale(0.6);

   }

   .rupeecurrency
   {
    transform: scale(0.8);
   }


 .rupee_data
{
    transform: scale(0.7);
} 
   .currency1 
   {
      position: relative;
      top:1px;
   }
}


/* Picks */

/* Search */

.search_item
{
    display: flex;
    align-items: center;
}
.carousel-item {
    margin-right:0px;
  }
  
  .carousel-item.with-gap {
    margin-right: 14px; 
  }


.input_search_data
{
    width:100%;
    height: 30px;
    outline: none;
    border: none;
    font-size: 16px;
    padding-left:10px;
    position: relative;
    box-sizing: border-box;
    border-left:2px solid var(--stroke);
    background-color: transparent;
}
.input_search_value_data
{
    display: flex;
    align-items: center;
    padding: 10px 6% 10px 0px;
}

.search_data
{
    display: flex;
}


.filter{
  
    background-color: var(--white);
    padding: 0px 0px 0px 8px;
    box-sizing: border-box;
    font-size:14px;
    font-family: var(--fontfamily);
    font-weight: 500;
}
.filtering
{
    background-color: var(--white);
    box-sizing: border-box;
    font-size:14px;
    font-family: var(--fontfamily);
    font-weight: 600;
}
.filteringdata
{
    padding: 8px 0px 28px 0px;
    box-sizing: border-box;
    font-size:14px;
    font-family: var(--fontfamily);
    font-weight: 600;
}

.search_image_data img
{
    height:100%;
    width:100%;
    object-fit: cover;
}
.check_filter
{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.select_cagtegory
{
    font-size: 14px;
}

.search_valueshow
{
    position: absolute;
    display: flex;
    justify-content: center;
     width:100%;
     box-sizing: border-box;
}

.circularprogress
{
    display: flex;
    justify-content: center;
     left:50%;
     top:50%;
     transform: translate(-50%,-50%);
     position: relative;
}

.search_image_data
{
    height:20vh;
    width:auto;
}

.search_result
{
    padding:0% 4%;
    width:100%;
    box-sizing: border-box;
}
.search_name
{
        font-size: 18px;
        font-weight: 600;
        font-family:'Montserrat';
        display: flex;
        line-height: 24px;
}

.book_button
{
    height:40px;
    border: 2px solid var(--primary) ;
    border-radius: 8px;
    background-color: var(--primary);
    color:white;
    cursor: pointer;
    font-size: 16px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.search_booking
{
    display: flex;
    justify-content: space-between;
}

.searchpriceIcon
{
    display: flex;
    justify-content: space-between;
    position: relative;
    top:2px;
    width:200px;
}

.searchreal_price
{
    color: gray;
    font-size: 16px;
    position: relative;
}
.searchexpected_price
{
    display: flex;
    align-items: center;
    position: relative;
    font-size: 16px;
    font-weight: 600;
}

.searchdiscount
{
    color: green;
    font-weight: 600;
    position: relative;
    font-size: 16px;
}

.searchpickrating
{
    color: var(--stroke);
    display: flex;
    align-items: center;
    font-size: 16px;
    position: relative;
}

.rupees
{
    transform: scale(0.9);
}
.searchcurrency
{
     position: relative;
     left:4px;
}

.search_showdata
{
     display: flex;
     justify-content: center;
     height: 100%;
     box-sizing: border-box;
     flex-direction: column;
}

.explore_image_search
{
     height:35px;
     width:35px;
     border-radius: 3px;
}

.explore_image_search img 
{
     width:100%;
     height:100%;
     object-fit: cover;
     border-radius: 1px;
}

.recommendedsimilar
{
    position: relative;
    left:-34px;
}

.search_show
{
    display: flex;
    grid-column-gap: 10px;
    padding-bottom: 10px;
   cursor: pointer;
}
.search_show_item
{
    display: flex;
    grid-row-gap:10px ;
    flex-direction: column;
    width:32vw;
    border-radius:0px 0px 4px 4px;
    position: absolute;
    box-sizing: border-box;
    z-index: 999;
    top:100%;
    margin-top: 20px;
}

.explore_name_search
{
    font-size: 14px;
    font-weight: 600;
    font-family:'Montserrat';
    display: flex;
    justify-content: center;
    line-height: 14px;
    padding-top: 4px;
}

.searching_data
{
    display: none;
}


.check_bunchfilter
{
  display: none;
}
/*
.item-value
{

   display: flex;
   align-items: center;
   box-sizing: border-box;
   color: var(--primary);
   border:1px solid var(--primary);
   padding: 0px 0% 0px 4px;
   border-radius: 20px;
   width: fit-content;
   margin-bottom: 10px;
   font-size: 12px;
   font-weight: 600;
   
}
*/
.item-value
{
    text-align: center;
    padding: 4px 14px;
    align-items: center;
    box-sizing: border-box;
    color: var(--primary);
    width: fit-content;
    border-radius: 4px !important;
    border: 1px solid #c7c2c2;
    line-height: normal;
    cursor: pointer;
      margin-bottom: 20px;
      font-size: 14px;
    font-weight: 500;
      display: flex;
      background: #faf7ff;
}
.item-value:hover {
    background: #faf7ff;
    border-color: var(--primary);
    opacity: 0.8;
}
/*
.clearicon
{
     transform: scale(0.6);
     color:var(--primary);
     box-sizing: border-box;
     top:2px;
     position: relative;
}
*/
.clearicon {
    top: 2px;
    right: -9px;
    -webkit-transform: scale(.6);
    transform: scale(.6);
    position: relative;
}
.totallength
{
    font-weight:600;
   position: relative;
   top:-10px;
   z-index: -999;
   padding-bottom: 10px;
   font-size: 18px;
}
.gallery_image
{
   
    width:100%;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    height:100%;
    position: absolute;
    box-sizing: border-box;
    background-color: var(--black);
}
.image_showgallery
{
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    position: absolute;
    box-sizing: border-box;
    aspect-ratio: 1.25;
}

.image_show img{

    max-height:100%;
    aspect-ratio: 1.25;
    max-width: 100%;
    object-fit: contain;
}
.image_shows img{

    max-height:100%;
    aspect-ratio: 1.25;
    max-width: 100%;
    object-fit: contain;
}

.image_shows
{
    height:auto !important;
    overflow: hidden;
    position: relative;
    width: auto !important;
    cursor: pointer;
    background-color: #868686;
}

.image_show
{
    height:auto !important;
    overflow: hidden;
    position: relative;
    width: auto !important;
    cursor: pointer;
    background-color: #868686;
}

.thumbnail
{
     display: flex;
     grid-column-gap: 6px;
     padding: 0px 20px 20px;
}
.thumbnailimage_show
{
    height:auto;
    overflow: hidden;
    aspect-ratio: 1.20;
    width: auto;
    cursor: pointer;
    background-color: #868686;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}
.thumbnailimage_show img{

    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}
.highlighted
{
    box-shadow: 0px 0px 0px 2px var(--primary);
}

.gallery_imagedata
{
   
    
     width:50%;
     top:50%;
     left:50%;
     transform:translate(-50%,-50%);
     position: absolute;
     aspect-ratio: 1.25;
}

.closeicon_image
{

    cursor: pointer;
    color: var(--white);
    display: flex;
    padding: 20px 20px 0px 0px;
    justify-content: flex-end;
    box-sizing: border-box;
    
}



.right_images
{     
        background-color: var(--white);
        border-radius: 0px 8px 8px 0px;
}

.view_image_gallery
{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: var(--white);
    align-items: center;
}

.image_datas
{
  
    max-height: 100%; 
    max-width:100%;
    aspect-ratio:1.50;
    object-fit: contain;
}


.image_datashow
{
   
   text-align: center;
   height:100% !important;
   overflow: hidden;
   position: relative;
   width: 100% !important;
   cursor: pointer;
   background-color: #868686;
    
}

@media (min-width:768px)
{
    .input_search_value_data
    {
        display: none;
    }
    .searchingfilter
    {
        position: absolute;
         color: #a3a3a3;
        padding-left: 10px;
       transform: scale(0.8);
       padding-top: 2px;
       right: 10px;
    }

}
@media (max-width:768px)
{
    
   .search_detail
   {
        display: none;
   }
   .image_datas
   {
      height:100%;
      width:100%;
      aspect-ratio:1.50;
      object-fit: contain;
   }
   .thumbnail
   {
    padding: 0px 20px 20px;
   }

   .recommendedsimilar
   {
       position: relative;
       left:-22px;
   }
  .gallery_imagedata
  {
    width:90%;
  }
    .check_bunchfilter
    {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 10px;
        text-align: center;
        grid-row-gap: 10px;
    }  
    .search_valueitem
    {
        display: none;
    }
   .searchingfilter
   {
      display: none;
   }
    .check_bunch
    {
        background-color: var(--white);
        height:36px;
        cursor: pointer;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        padding: 0px 2px;
    }

    .check_bunch:hover{
        background-color: var(--primary);
        color: white;
    }

    .search_show_item
    {
         width:100%;
         left:0px;
         margin-top:0px;
    }

    .totallength
    {
        padding-bottom: 10px;
    }

    .select_bunchcagtegory
    {
         font-size: 14px;
    }
    .skeletonfilter
    {
        width:100px;
        box-sizing: border-box;
    }
    .search_category
    {
        display: flex;
        width:140%;
        position: relative;
        left:-20%;
    }

    .skeletoncategoriesdata
    {
        display: flex;
        justify-content: space-between;
        position: relative;

    }
  .skeletonitem
  {
   
    width:100%;
    
  }
    .search_name
    {
        font-size: 16px;
    }
    .searchreal_price
    {
        font-size: 14px;
    }
    .searchexpected_price
    {
        font-size: 14px;
    }
    .searchdiscount
    {
        font-size: 14px;
    }
    .searchpickrating
    {
        font-size: 14px;
    }
    .search_result
    {
        padding: 0% 2%;
    }
    .rupees
    {
        transform: scale(0.8);
    }

    .searchfilter
    {
         display: none;
    }
    .searching_data
    {
        display: flex;
        padding: 0px 2%;
        align-items: center;
        position: relative;
    }
    .input_searching
    {
        width:100%;
        height: 38px;
        border: 1px solid var(--primary);
        border-radius: 5px;
        font-size: 16px;
        padding-left: 20px;
        position: relative;
        box-sizing: border-box;
    }
}

@media (max-width:480px)
{
    .search_name
    {
        font-size: 12px;
    }
    .searchreal_price
    {
        font-size: 12px;
    }
    .searchexpected_price
    {
        font-size: 12px;
    }
    .searchdiscount
    {
        font-size: 12px;
    }
 
    .searchpickrating
    {
        font-size: 12px;
    }
    .book_button
    {
        font-size: 12px;
        height:34px;
    }
    .search_category
    {
        min-width: 16rem;
    }

    .search_result
    {
        padding: 0% 0% 0% 1%;
    }
    .search_showdata
    {
        display: flex;
        justify-content: flex-start;
        padding-top: 7px;
    }
    .rupees
    {
        transform: scale(0.7);
    }
    .searchcurrency
    {
        position: relative;
        left: -1px;
    }

    .search_rating
    {
        position: relative;
        margin-top:-14px;
    }
    .select_bunchcagtegory
    {
        font-size: 10px;
    }
}

/* Search */

/* Suggest */
@media (max-width:767px)
{
    .input_search_item
    {
        height: auto;
        padding: 4px;
    }
}

@media (max-width:600px)
{
    .image_showgallery
    {
     width:100%;
    }
}

/* Suggest */

/* Checkout */
.showingdata
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-column-gap:12px;
    margin-bottom: 20px;
}
.sorted
{
    border-radius: 2px;
    border: 1px solid #9B9A9D;
    background: #FFF;
    padding: 8px;
    display: flex;
    grid-column-gap:8px;
    cursor: pointer;
}
.Sort
{
    color: #373737;
    font-family: var(--fontfamily);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal; 
}

.recently
{
   color: #000;
   font-family: var(--fontfamily);
   font-size: 12px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
}

.checkout_item
{
    display: flex;
    width: 100%;
}

.checkout_rating
{
    padding:20px 20px 20px 0px;
    display: flex;
    justify-content: space-between;
    width:100%;
    box-sizing: border-box;
    grid-column-gap: 10px;
}

.carousel-width
{
    width:45%; 
    display: flex;
    align-items: center; 
}
.checkout_data 
{
     height: 100%; 
    width: 100%;
     float: left; 
    display: grid;
    grid-row-gap: 18px; 
}


.checkout_images{

    height:auto !important;
    overflow: hidden;
    position: relative;
    width: auto !important;
    cursor: pointer;
    background-color: white;
   
}
.checkout_images img
{
    max-width: 100%;
    max-height: 100%;
    object-fit:contain;
    aspect-ratio:1.20;
     -o-object-fit: contain;
     -o-object-position: center;
     object-position: center;

}


.dp-18
{
    padding-inline: 0px;
}
.checkout_image 
{
  
    height:auto !important;
    overflow: hidden;
    position: relative;
    width: auto !important;
    cursor: pointer;
    background-color: #868686;
    display: flex;
    justify-content: center;
    border-radius: 6px;

}

.checkout_image img
{
   max-width: 100%;
   max-height: 100%;
   object-fit:contain;
   aspect-ratio:1.20;
   -o-object-fit: contain;
   -o-object-position: center;
   object-position: center;
}
.carousel .slider-wrapper { 
    border:none !important;
 }
.carousel-rooted {

     padding: 20px 20px 10px !important ; 

}

@media (max-width:768px)
{
    .carousel-rooted {
        padding: 0px !important;
        margin-bottom: 10px !important;
   }

   .checkout_image
   {
    border-radius: 0px !important;
   }
}

li.dot.selected { opacity: 1 !important; background: #8b62da !important;
  border: none !important;
}

.carousel .control-dots .dot { 
    background: #fff ; 
    border: 1px solid #aeaeae !important; 
    border-radius: 50% !important; 
    cursor: pointer !important;
     display: inline-block !important; 
     filter: alpha(opacity=30) !important;
      height: 8px !important; 
      margin: 0 8px !important;
       opacity: .3; 
       transition: opacity .25s ease-in ; 
    width: 8px !important;
 }

/*
.productImage
{
    border-radius: 12px;
    cursor: pointer;
    height: 28em;
    overflow: hidden;
    position: relative;
    width: 100%;
}
.stylescarousel
{
    display: block;
    height: 100%;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
}
*/
.productImage
{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%; /* Set your desired maximum width */
    max-height: 100%; /* Set your desired maximum height */
    overflow: hidden;
    width:300px;
    height: 300px;
  
}
/*
.checkout_image
{
    height: 440px !important;
    overflow: hidden;
    position: relative;
    border-radius: 0.7rem;
}
.checkout_image img 
{
    position: absolute;
    max-height: 100%;
    z-index: 4;
    width: auto !important;
    margin: auto;
    max-width: 100% !important;
    display: block;
    left: 0;
    height: auto;
    top: 0;
    bottom: 0;
    right: 0;
}
*/

.bottom-fixed
{
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
}

.checkout_image_skeletons img{
    width:100%;
    height:100%;
    object-fit: cover;
}
.checkout_image_skeletons
{
    height:344px;
    width:480px;
    margin-bottom: -6px;
    position: relative;
    top:-4px;
}




.select_checkout
{
   display: flex;
   grid-column-gap: 2%;
   width:100%;
   grid-row-gap: 6px;
   flex-wrap: wrap;
}
.checkout_star
{
    display: flex;
    background-color: white;
    box-shadow: 0px 0px 10px -6px rgb(132, 132, 137);
    padding: 2px 10px;
    justify-content: center;
    border-radius: 6px;
    align-items: center;

}


.checkout_rating_name
{
    line-height: 26px;
    font-weight: 500;
    font-size: 14px;
    position: relative;
    left:-4px;
}


.rating_name
{
    color: var(--darkgray);
    font-weight: 500;
    font-size: 14px;
    padding-left: 2px;
    position: relative;
     top:3px
}

.checkout_starIcon
{
    color: var(--primary);
    opacity: 1;
    transform: scale(0.8);
    position: relative;
    left: -6px;
    top:1.5px;
}

.sharelink
{
    background-color: var(--white);
    padding: 18px;
    display: flex;
    box-shadow: var(--box-shadow);
    grid-column-gap: 18px;
    border-radius: 4px;
    box-sizing: border-box;
   justify-content: center;
}
/* YourCarouselStyles.css */
.carousel-container {
    width: 100%;
    max-width: 800px; /* Adjust the width as needed */
    margin: 0 auto;
  }
  
  .carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    font-size: 24px;
    color: #333; /* Arrow color */
    cursor: pointer;
  }
  
  .carousel-arrow:focus {
    outline: none;
  }
  
  .carousel-arrow.prev {
    left: 10px; 
  }
  
  .carousel-arrow.next {
    right: 10px; 
  }
  

.review_data
{
    display: flex;
    justify-content: space-between;
    width:100%;
}
.customer-said
{
    font-size: 16px;
    font-weight: 600;
    font-family: var(--fontfamily);
    line-height: normal;
 
}
.customer-review
{
     line-height: 20px;
}
.feedback-review
{
    line-height: 20px;
    margin-top: -12px;
}
.show_rating
{
    display: grid;
    grid-template-columns: 0fr 2fr 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    align-items: center;
}

.star-service
{
    font-weight: 600;
    font-family: var(--fontfamily);
}
.review_details
{
     display: grid;
     grid-template-columns: auto auto auto;
     flex-wrap: wrap;
     justify-content: space-between;
     grid-row-gap: 12px;
     grid-column-gap:10% ;
}

.show_rated
{
    font-weight: 500;
}

.rating_review
{
    display: flex;
    align-items: center;
    grid-column-gap: 1%;
    box-sizing: border-box;
    padding-top: 2%;
}

.rating_average
{
    font-weight: 500;
    font-size: 18px;
}
.rating_average_value
{
    font-weight: 600;
    font-size: 24px;
}
.rating_review_data
{
    display: flex;
    flex-direction: column;
    align-items: center;
   
}

.rating_review_datas{
    display: flex;
    flex-direction: column;
}

.all-reviews
{
    color:var(--primary);
    cursor: pointer;
    text-decoration: underline;
  
}
.starrating
{
    display: flex;
    grid-column-gap: 4px;
    font-weight: 500;
    font-size: 14px;
    align-items: center;
}
.star_value_data
{
    display: flex;
}

.starvalue
{
    transform: scale(0.6);
    color: rgb(2, 74, 2);
    position: relative;
    top:2px;
    
}
.starvalues
{
    transform: scale(0.5);
    position: relative;
   
}
.overall_starrating
{
    display: flex;
    font-weight: 500;
    font-size: 12px;
    align-items: center;
    background-color: green;
    padding: 0px 0px 0px 6px;
    color: white;
    border-radius: 8px;
    
    
}

.overall_caption
{
    display: flex;
    align-items: center;
    grid-column-gap: 8px;
    font-weight: 600;
    justify-content: space-between;
}

.review_message
{
     font-weight: 500;
     font-size: 14px;
     line-height: 26px;
     padding: 4px 0px;
}
.review_payment
{
    font-weight: 500;
    font-size: 14px;
    line-height: normal;
}

.fibermanual
{
    display: flex;
    grid-column-gap: 10px;
    padding-top: 10px;
    align-items: center;
}

.fibermanualrecord
{
    transform: scale(0.8);
    display: flex;
}

.review_name
{
    font-size: 14px;
    color: var(--gray);
}


.image_data
{
    display: flex;
    padding-top: 8px;
    grid-column-gap: 6px;
    flex-wrap: wrap;
}
.image_picture
{
    width:110px;
    cursor: pointer;
    object-fit: cover;
    height: 80px;
}
.image_profile_data
{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto;
    flex-wrap: wrap;
    padding-top: 8px;
    grid-gap: 2px;
}
.reviewed_on
{
     font-size: 12px;
     font-family: var(--fontfamily);
     font-weight: 500;
     color: gray;
}
.overall_review
{
    padding-bottom: 1%;
}
.select_service
{
   
     font-weight: 600;
     font-size: 18px;
     
}
.select_bhk
{
    display: flex;
    grid-column-gap: 14px;
    margin-top:10px;
}
.bhk
{
    background-color: var(--white);
    font-weight:500;
    border-radius:4px;
    padding: 11px 17px;
    border:1px solid #c3c3ce;
    cursor: pointer;
    font-size: 14px;
    color:#717171;

}
.bhk:hover
{
    background-color: var(--white);
    color:#717171;
     font-weight: 500;
     font-size: 14px;
}
.bhk1
{
    background-color: var(--white);
    font-weight:500;
    border-radius:4px;
    padding: 11px 17px;
    border:1px solid var(--primary);
    cursor: pointer;
    color: var(--primary);
    font-size: 14px;
}
.checkout_rating_rate
{
    display: flex;
}
.pricevariant
{
   display: flex;
   position: relative;
   grid-column-gap: 8px;
   box-sizing: border-box;
   margin-left: 3px;
   align-items: center;
}
.pricevariants
{
    display: flex;
    position: relative;
    grid-column-gap: 8px;
    box-sizing: border-box;
    align-items: center;
    top:0px;
}
.variant-option
{
   
    font-family: var(--fontfamily);
    font-weight: 500;
    position: relative;
    top: 0;
    font-size: 14px;
    margin: 4px 0px;
    display: block;
}
.money_cart
{
     display: grid;
     grid-row-gap: 10px;
}

 .expected_pricevariant
 {
   display: flex;
   align-items: center;
   position: relative;
    font-weight: 600;
 }

 .real_pricevariant
 {
   color: gray;
   position: relative;
   
 }

 .discountvariant
 {
   color: green;
   font-weight: 600;
   position: relative;
 
 }
 .price-data
 {
    color: #827F7F;
    font-family: var(--fontfamily);
    font-weight: 500; 
 }
 .final_price
 {
     display: flex;
     grid-column-gap: 4px;
 }

 .online_pay
 {
    display: flex;
    grid-column-gap: 4px;
    width: 100%;
 }
 .home_outlined
 {
    position: relative;
    top:6px;
 }


 .online_paid
 {
    display: flex;
    grid-column-gap: 8px;
 }


  

 .online_date
 {
    display: flex;
    grid-column-gap: 4px;
    align-items: center;
    padding-top: 4px;
 }
.payment_date
{
    padding-left: 10px;
    width:100%;
}
.date_timing
{
     display: flex;
     grid-column-gap:8px;
}
 .payment_online
 {
     display: flex;

 }

 .payment_onlines
 {
     display: flex;
 }
 .cash
 {
     font-size: 14px;
     font-family: var(--fontfamily);
     color: #615F5F;
     font-weight: 500;
 }


 .breadcrumb { 
    margin: 8px 0 8px; 
}
.breadcrumb1
{
    margin: 0px 0px 10px;

}
.displayDatalength
{
    color: #000;
    font-family: var(--fontfamily);
     font-size: 14px;
     font-weight: 500;
    line-height: normal;
}
.displayDataspace
{
    color: #000;
    font-family: var(--fontfamily);
     font-size: 16px;
     font-weight: 500;
    line-height: normal;
}
.displayDataitems
{
    color: #000;
    font-family: var(--fontfamily);
     font-size: 12px;
     font-weight: 500;
    line-height: normal;
}

.displaytitle
{
    font-weight:500 !important;
    font-family: var(--fontfamily);
    font-size: 16px;
}
 .total_money_service
 {
    display: flex;
    grid-column-gap: 4px;
    align-items: center;
    margin-top:0px;
 }
 .total_price
 {
     font-weight: 600;
     font-size: 18px;
 }

 .total_price1
 {
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
 }
 .totalprices
 {
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
 }

 .product_img
 {
    
    height:56px;
    width: auto;
    background-color: #aaa9a9;
    border-radius: 4px; 
 }
 .background
 {
    box-shadow: none !important;
    border-radius: 4px !important;
    border: 1px solid #E9E9E9 !important;
    background: rgb(245, 73, 73) !important;
 }

 .product_img img 
 {
    aspect-ratio: 1;
   height:100%;
   width:100%;
   object-fit: contain; 
 
 }
 .product_details .order_items:first-child
 {
    padding-top: 0px;
 }
 .product_details .order_items
 {
    padding-top: 10px;
 }
 .totalmoney
 {
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    position: relative;
 }

 .total_price2
 {
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-left: -4px;
 }

 .booking_now
 {
    background-color: var(--primary);
    border-radius: 6px;
    padding: 9px 0.6em;
    font-size: 16px;
    color: var(--white);
    border:2px solid  var(--primary);
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    width:100%;
 }
 .booking_now1
 {
    background-color: var(--primary);
    border-radius: 6px;
    padding: 9px 0.6em;
    font-size: 16px;
    color: var(--white);
    border:2px solid  var(--primary);
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
 }

 .booking_button
 {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 20px;
    margin-top: 10px;
 }
 .total_pay
 {
    font-weight: 550;
    font-size: 16px;
 }
 .totalcost
 {
    display: flex;
    justify-content: space-between;
 }

 .remove-coupon
 {
    background: transparent;
    outline: none;
    border: none;
    cursor:  pointer !important;
    font-size: 14px;
    text-decoration: none;
    font-weight: 600;
    line-height: 18px;
    color: var(--primary);
 }
 .total_discount{
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
   color: #39B64A;
   font-family: var(--fontfamily);
 }
.totalsave{
    color: #39B64A;
}
.priceonline
{
    font-family: var(--fontfamily);
    font-size: 16px;
    font-weight: 600;
}

.price_online
{
    font-family: var(--fontfamily);
    font-size: 12px;
    font-weight: 500;
}
.total_save
{
    display: flex;
    grid-column-gap: 4px;

}

 .border
 {
     border-top: 1px dashed black;
 }

 .totatcostmoney
 {
     display: grid;
 }

 .remove_discount
 {
     transform: scale(0.6);
     color: #39B64A;
     position: relative;
     left:4px;
 }
 .currency_rupee
 {
    transform: scale(0.7);
    color: #39B64A;
    position: relative;
    left: 6px;
 }
 .currency_rupees
 {
     transform: scale(0.7);
     position: relative;
     left: 6px;
 }
.total_money
{
    font-weight: 550;
    font-family: var(--fontfamily);
}

 .addCart_button
 {
   margin-left: 10px;
   text-transform:'none'
 }
.loginsbutton{
    background-color: var(--primary);
    padding: 18px 20px;
    font-weight: 500;
    color: var(--white);
    font-size: 16px;
    font-family: var(--fontfamily);
    border-radius: 8px;
}
 
 .addtocheckout
 {
    display: flex;
    grid-column-gap: 2%;
    align-items: center;
 }
 .gocheckout
 {
     display: flex;
     align-items: center;
     position: relative;
 }
 .price-included
 {
    display: flex;
    grid-column-gap: 4px;
    padding-bottom: 4px;
 }
 .removeIcon
 {
     background-color: transparent;
     border: none;
     outline: none;
     cursor: pointer;
     display: flex;
     align-items: center;
     padding: 2px 4px;
     box-sizing: border-box; 
 }
.datacount
{
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    padding: 4px 6px;
    box-sizing: border-box; 
}
.removeIcon:hover{
    background: #eee5ff;
}
.datacount:hover{
    background: #eee5ff;
}

table{
    border-collapse: collapse;
    
}

td 
{
    border:1px solid rgb(210, 208, 208);
    width:34px;  
}
td:hover
{
     background: #eee5ff;;
}

.checkout_image_skeleton
{
    height:350px;
    width:480px;
    margin-bottom: -6px;
}

.item_additional
{
    padding: 20px ;
    display: grid;
    grid-row-gap: 10px;
}
.item_review
{
    display: flex;
    justify-content: space-between;
}

.additional_information
{
    text-decoration: underline;
    cursor: pointer;
}
.service_includes
{
     font-size: 18px;
     font-weight: 550;
     
}
.done_tick{
    display: flex;
    grid-column-gap: 10px;
    align-items: center;
}
.doneIcon
{
     transform: scale(0.8);
}

.additional_details
{
     width:90%; 
         
}
.date_details
{
    padding-right: 10px;
}

.coupon_image 
{
     width:120px;
     height:115px;
    
}
.coupon_image img
{
    width: 100%;
    height: 100%;
    object-fit:cover;  
}


.coupon_applied
{
    display: grid;
    grid-template-columns: 140px auto;
}
.coupon_title
{
     font-size: 18px;
    font-weight: 550;
}

.coupon_description
{
      color: var(--stroke);
}

.coupon_applydata
{
   display: flex;
   flex-direction: column;
   grid-row-gap: 6px;
}

.coupon_code
{
     border: 1px dashed black;
     padding: 4px 8px;
     background-color: rgb(172, 150, 194);
       
}
.apply_coupon
{
     display: flex;
     align-items: center;
     justify-content: space-between;
      margin-top: 6px;
}

.applydatacoupons
{
    background-color: transparent;
    cursor: pointer;
    border: none;
    outline: none;
    color: var(--primary);
    font-weight: 550;
}
.checkoutbutton
{
    background-color: var(--primary);
    padding: 18px 20px;
    font-weight: 500;
    color: var(--white);
    font-size: 16px;
    font-family: var(--fontfamily);
    border-radius: 8px;
    white-space: nowrap;
}
.Pay-online
{
    font-size: 14px; 
    font-family: var(--fontfamily);
    position: relative;
    
}
.location-delivery
{
     display: flex;
     justify-content: space-between;
     width: 100%;
}
.location-service
{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}


.Off-online
{
    font-size: 14px; 
    font-family: var(--fontfamily);
}

.label_title
{
     font-size: 16px;
     font-family: var(--fontfamily);
     font-weight: 600;
}
.label_titles
{
     font-size: 14px;
     font-family: var(--fontfamily);
     font-weight: 500;
}
.marginschedule
{
    margin-bottom: 12px;
}

@media (min-width:768px)
{
   
    .reveiwing_data
    {
        display: none;
    }
    .add_button
    {
        display: none;
    }
   
 
 
}

@media (max-width:900px)
{

 
    .checkout_image_skeletons
    {
        height:300px;
        width:100%;
        margin-bottom: -6px;
        position: relative;
        top:-4px;
    }

    .select_bhk
    {
        grid-column-gap: 10px;
        width:105%;
    }
   .review_data
   {
    display: grid;
    grid-template-columns: auto auto ;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-row-gap: 12px;
   }

    .review_details
    {
        display: grid;
       grid-template-columns: auto auto ;
       flex-wrap: wrap;
       justify-content: space-between;
       grid-row-gap: 12px;
    }

    .service_note
    {
        font-size: 14px; 
      cursor: pointer;
    }
    

}
@media (max-width:768px)
{
    .showingdata
    {
        display: flex;
        justify-content: space-between;
        align-items: center;
        grid-column-gap:12px;
        margin-bottom: 16px;
    }
   
    .sorted
    {
        border-radius: 2px;
        border: 1px solid #9B9A9D;
        background: #FFF;
        padding: 8px;
        display: flex;
        cursor: pointer;
    }
    .Sort
    {
        color: #373737;
        font-family: var(--fontfamily);
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal; 
    }
    
    .recently
    {
       color: #000;
       font-family: var(--fontfamily);
       font-size: 10px;
       font-style: normal;
       font-weight: 500;
       line-height: normal;
    }

    .checkout_image img
    {
        height:100%;
        cursor: pointer;
        aspect-ratio: 1.25;
        width: 100%;
    }
    .checkout_data 
    {
         height: 100%; 
        width: 100%;
         float: left; 
        display: grid;
        grid-row-gap: 10px; 
    }
   
    .displayDatalength
    {
        color: #000;
        font-family: var(--fontfamily);
         font-size: 14px;
         font-weight: 500;
        line-height: normal;
        }

        .displayDataspace
     {
    color: #000;
    font-family: var(--fontfamily);
     font-size: 16px;
     font-weight: 500;
    line-height: normal;
   }

.displayDataitems
{
    color: #000;
    font-family: var(--fontfamily);
     font-size: 12px;
     font-weight: 500;
    line-height: normal;
}
  
    .displaytitle
    {
        font-size: 14px;
    }
    .checkout_images img
    {
        height:100%;
        cursor: pointer;
        aspect-ratio: 1.25;
        width: 100%;
    }
    .viewsimilarservice li
    {
        margin-left: 10px;
    }
  
    .money_cart
    {
         display: flex;
        justify-content: space-between;
    }
    .total_money_service
    {
       display: grid;
       align-items: center;
       margin-top:0px;
    }
 .p-16
 {
    padding-inline: 0px !important;
 }


 .service-margin
 {
    margin-bottom: 10px !important;
 }
 .mobile-header
 {
    padding: 10px 10px;
 }
 .mobile-bodypadding
 {
    padding-inline: 10px;
 }

 .mobile-bodymargin
 {
    
    margin-inline: 10px !important;
 }
 .mmt-0
 {
    padding-top: 0px !important;
 }

 .priceonline
 {
    font-size: 14px;
 }
 .text-wrapped
 {
    white-space: nowrap; 
    overflow: hidden;    
    text-overflow: ellipsis;
  
 }

    .checkout_image_skeleton
    {
        
        margin-bottom: 8px;
        width:100%;
        height:280px
    }
    .checkout_image_skeletons
    {
        
        height:280px;
        width:100%;
    }
  .pricevariants
  {
      display: flex;
      grid-column-gap: 4px;
     
  }

    .checkout_item
    {
        display: flex;
        flex-direction: column;
        margin: 0px;
    }
    .checkout_rating
    {
        padding: 0px 20px 20px;
        width:100%;
        box-sizing: border-box;
       
    }
    .carousel-width
    {
        width:100%
    }
    .checkout_name
    {
        padding-left: 0px;
        font-size: 16px;
        padding-bottom: 4px;
    }
    .checkout_star
    {
        padding-bottom: 0px;
        display: flex;
        align-items: center;
        
    }
    .checkout_rating_name
    {
        font-size: 12px;
    }
    .rating_review_datas
    {
        display: none;
    }
    .datacount
    {
        font-weight: 500;
        font-size: 14px;
        text-align: center;
       padding: 2px 4px;
    }
    table{
        border-collapse: collapse;
    }
    td 
    {
        border:1px solid rgb(210, 208, 208);
        width:34px;
    }
   .removeIcon
   {
    transform: scale(0.9);
    padding: 2px 4px;
   }

    .rating_name
    {
        font-size: 12px;
        top:0px;
        position: relative;
    }
    .checkout_starIcon
    {
        transform: scale(0.7);
        left: -7px;
        top:1px;
    }
  
    .select_checkout
    {
       
        grid-column-gap: 2%;
        grid-template-columns: 80px 60px 120px;
        padding: 10px 0px;
        
    }
    .select_service
    {
       
        font-size: 14px;
    }
    .select_bhk
    {
        padding-left: 0px;
        grid-column-gap: 10px;
    }
 
    .bhk{
        font-size: 12px;
        padding: 9px 15px;
    }
    .bhk1
    {
        font-size: 12px;
        padding: 9px 15px;
    }
    .checkout_rating_rate
    {
        display: flex;
        padding-left: 0%;
        align-items: center;
    }
    .pricevariant
    {
       
        padding: 0px;
        grid-column-gap: 12px;
    }
   
    .real_pricevariant
    {
        font-size: 12px;
        position: relative;
    }
    .discountvariant
    {
        font-size: 12px;
        position: relative;
    }
 
   
    .addCart_button
    {
      margin-left: 0px;
      margin-bottom: 6px;
      
    }
   
    .total_price1
    {
        font-size: 16px;
    }
  
    .rating_review
    {
        display: none;
    }

    .show_rating
    {
        display: grid;
    grid-template-columns: 0fr 1fr auto;
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    align-items: center;
    }

    .review_data
    {
        display: block;
    }

    .review_details
    {
        display: block;
    }
   
    .review_message
    {
         font-weight: 500;
         font-size: 12px;
         line-height: 24px;
    }
    
    .review_name
    {
        font-size: 12px;
        color: var(--gray);
    }

    .add_button
    {
        margin: 20px 0px -18px;
    }

    .service_includes
    {
        font-size: 16px;
    }

  
    .additional_information
    {
        font-size: 12px;
    }
    .service_note
    {
        font-size: 12px; 
        cursor: pointer;
    }
  

    .addtocheckout
    {
        display: flex;
        grid-column-gap: 2%;
    }
    .checkoutbutton
    {
        background-color: var(--primary);
        padding: 12px 14px;
        font-weight: 500;
        color: var(--white);
        font-size: 14px;
        font-family: var(--fontfamily);
        border-radius: 8px;
        white-space: nowrap;
    }

 

}

@media (max-width:600px)
{
    

    .checkout_image_skeleton
    {
        height:160px;
        margin-bottom: 8px;
    }
    .checkout_image_skeletons{
        height:160px;
        width:100%;
    }
    .m-6 {
        margin: 0px 6px;
    }
    .image_picture
    {
        width:80px;
        cursor: pointer;
        height:60px;
    }

    .all-reviews
    {
        font-size: 14px;
    }
    .rating_name
    {
        font-size: 12px;
        position: relative;  
    }

    .loginsbutton{
        background-color: var(--primary);
        padding: 12px 14px;
        font-weight: 500;
        color: var(--white);
        font-size: 14px;
        font-family: var(--fontfamily);
        border-radius: 8px;
    }

  
    .select_checkout
    {
        display: flex;
        flex-wrap: wrap;
        grid-row-gap:6px;
        margin-bottom: 2px;
        
    }
    
}

@media (max-width:360px)
{
  


    .checkout_image_skeleton
{
    height:160px;
    width:96vw;
    margin-bottom: 8px;
}
.checkout_image_skeletons{
    height:160px;
}
}

/* Checkout */

/* Add cart */
.shareIcon
{
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    transform: scale(0.9);
}
.shareItem
{
    display: flex;
    flex-direction: column;
    grid-row-gap: 6px;
    align-items: end;
}

.service_area
{
    margin:3% 0%;
    padding:3%;
    box-shadow: var(--box-shadow);
    background-color: var(--white);
    height:auto;
}
  .addCart
  {
     
      display: flex;
      grid-column-gap: 20px;
  }
  .shopCart
  {
   color: var(--primary);
   transform: scale(1.4);
   border: 1px solid var(--primary);
   padding: 5px;
   border-radius: 50%;
   cursor: pointer;
  }

 .addService_image
 {
    width:30vw;
    height:20vw;
    object-fit: fill;
 } 

 
 
 .service_time
 {
    display:grid;
    grid-template-rows: 0.12fr 0.12fr 0.12fr 0.12fr 0.12fr;
    grid-row-gap: 10px;
 }
 .description
 {
    line-height: 22px;
    font-size: 14px;
 }

.accountCircleOut
{
    transform: scale(0.8);
}
 .accountCircle
 {
    color: var(--primary) ;
    transform: scale(1.2);
 }

 .rate{
    
    color: var(--black);
    padding-left: 5px;
 }
 .selected_rating
 {
    display: flex;
    align-items: center;
    font-size: 20px;
    
 }
 .selected_star
 {
    display: flex;
    align-items: center;
    font-size: 16px;
    background-color: var(--primary);
    color: var(--white);
    font-weight: 500;
    padding: 2px 4px 2px 0px;
    border-radius: 4px;
 }

 .selected_rate
 {
    padding-left: 10px;
 }
 .selected_staff
 {
    display: flex;
    align-items: center;
    font-size: 18px;

 }

 .selected_time
 {
    display: flex;
    align-items: center;
    font-size: 18px;
 }

 .selected_priceIcon
 {
    display: flex;
    grid-column-gap: 8px;
    align-items: center;
 }
 .locationIcon
 {
    margin: -6px 0px -14px;
 }

 .mylocationIcon
 {
    display: flex;
    align-items: center;
    padding-top: 10px;
    width:100%;
    justify-content: space-between;
 }

 .reviews-idea
 {
     font-size: 14px;
     font-weight: 500;
     font-family: var(--fontfamily);
     line-height: 20px;
     padding-bottom: 8px;
 }

 @media (max-width:768px)
 {
     .selected_staff
     {
         font-size: 16px;
     }

     .selected_time
     {
        font-size: 16px;
     }

     .accountCircle 
     {
        transform: scale(1);
     }
     .shareIcon
     {
         cursor: pointer;
         position: relative;
        
     }
     .sharelink{
        position: relative;
        display: grid;
        padding: 16px;
    
     }
     

     
 }
/* Add cart */

/* Schedule */

.addIconaddress
{
    display: flex;
    grid-column-gap: 10px;
    align-items: center;

}

.schedule
{
    display: grid;
    grid-row-gap: 16px;
}
.service_note
{
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: normal;
    font-family: var(--fontfamily);
    cursor: pointer;
}
.timing
{
    border-radius: 8px;
   background: #FFF;
   box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.08);
   padding: 6px;
   margin-top: 16px;
   text-align: center;
   font-size: 12px;
}


.experience-service
{
    padding:10px;
}
.experience-services
{
  display: grid;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding: 0px;
}
  .booking_success
  {
      background: #1B8C41;
     
  }
  .sharedlogo
  {
    position: relative;
    top:20px;
    display: flex;
    justify-content: end;
  }
.booked_successed
{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
    
}


.timing:hover
{
    cursor: pointer;
    background-color: var(--primary);
     color: var(--white);
     font-weight: 500;
}

.timingdata
{
    cursor: pointer;
    background-color: var(--primary);
     color: var(--white);
     font-weight: 500;
     border-radius: 8px;
     box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.08);
     padding: 6px;
     margin-top: 16px;
     text-align: center;
     font-size: 12px;
}


.service_available
{
    font-style: normal;
    font-weight: 400;
    line-height: 24px;  
    border-radius: 8px;
    opacity: 0.4;
   background-color: #C8C8C8;
   text-align: center;
   width:22vw;
   padding: 12px 0px;
   color:var(--black);
   cursor: pointer;
   
}


.mylocation
{
     display: flex;
     align-items: center;
     grid-column-gap: 10%;
}
.locationIcon
{
     display: flex;
     align-items: center;
     grid-column-gap: 10px;
     cursor: pointer;
}
.locationaddIcon
{
        display: flex;
     align-items: center;
     grid-column-gap: 10px;
}

.addicon_data
{
    border: 1px solid black;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.dashed_border{
    border-top:1px dashed black ;
}

.dashedborder
{
    border-top:1px dashed black ;
  top:4px;
  position: relative;
}

.direction
{
     text-transform: uppercase;
     font-weight: 500;
     font-family: var(--fontfamily);
     font-size: 14px;
}
.addressdate
{
    line-height: 0px !important;
    font-family: var(--fontfamily);
    font-weight: 600 !important;
    margin-bottom: 20px;
}
.textareaauto
{
   width:100%;
    padding: 10px;
    box-sizing: border-box;
}

.textareaautosize
{
    width:100%;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: 500;
    font-family: var(--fontfamily);
}
.oldservicedata
{
  flex:1;
}
.arrowback
{
    color: var(--primary);
    padding: 0px;
    margin: 0px;
}
.booked
{
    width:60px;
}

.accepted_details
{
    display:grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 20px;
   
}
.bookingdata{

  display: flex;
  flex-direction: column;
  align-items: flex-end;
    grid-row-gap: 20px;

}

.accepted
{
    color: #b42c03;
    font-family: var(--fontfamily);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;

}
.feedback
{
    display: grid;
    grid-row-gap: 10px;
}
.feed-back
{
    color: var(--black);
    font-family: var(--fontfamily);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.feedback_data
{
    cursor: pointer;
}

.add-photo
{
    border: 1px dashed var(--black);
    border-radius: 8px;
    box-sizing: border-box;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height:100px;
    cursor: pointer;
}
.booking-cancelled
{
    display: flex;
    justify-content: space-between;
}
.bookingId-cancelled
{
  
    display: grid;
    grid-row-gap: 4px;
    padding: 0px 0px 10px 0px;
}
.booking-quantity
{
    display: grid;
    grid-row-gap: 4px;
    margin-bottom: 4px;
}
.saveaddress{
    text-transform: uppercase;
}



.additional_title
{
     font-size: 14px;
     font-family: var(--fontfamily);
     font-style: normal;
     font-weight: 500;
     line-height: normal;
     padding-right: 4px;
     text-transform: capitalize;
     width:80%;
    
     
}

.homeicon
{
    display: flex;
    border-radius: 20px;
    border: 1px solid #E7E7E7;
    background: #FFF;
    align-items: center;
    grid-column-gap:8px;
    padding:6px;
    cursor: pointer;

}


.homeicon:hover
{
    cursor: pointer;
    background-color: var(--white);
     color: var(--primary);
     border-radius: 20px;
     border: 1px solid var(--primary);
     font-weight: 500;
}


.homeoutlined
{
    display: flex;
    align-items: center;
}

.addressdata
{
    display: flex;
    grid-column-gap: 4%;
}


.google iframe
{
    width: calc(100% + 20px)!important;
}



.address_selection
{
     display: grid;
     grid-row-gap: 10px;
     padding: 20px 0px;
}

.address_location
{
    padding: 0px 20px 6px ;
}
.address_locations
{
    padding: 0px 20px;
    display: grid;
    grid-row-gap: 10px;
}
.address_locationprocessed
{
    padding: 0px 20px 8px ;
}
.address_confirmlocation
{
    padding: 0px 20px;
}


.solid_border
{
    border:1px solid var(--stroke);
    opacity: 0.4;
}

.solid_borderdata
{
    border:1px solid var(--stroke);
    opacity: 0.4;
    margin: 20px 0px 6px;
}

.service_delivery
{
    display: flex;
    grid-column-gap: 10px;
}

.delivery_card
{
    display: flex;
    grid-column-gap: 10px;
    align-items: center;
}

.delivery_address
{
    color: rgba(0, 0, 0, 0.60);
    font-family: var(--fontfamily);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.calendermonth
{
    color: var(--black);
    opacity: 0.4;
    cursor: pointer;
}

.addcalender
{
    display: flex;
   border: 1px solid black;
   opacity: 0.4;
   border-radius: 50%;
   position: relative;
   top:4px;
}
.new_card
{
    font-weight: 500;
    display: flex;
    align-items: center;
    line-height: normal;
    cursor: pointer;
}

.current-address
{
      font-size: 14px;
      font-family: var(--fontfamily);
      font-weight: 400;
      width:300px;
}
.current-addressline
{
    font-size: 12px;
    font-family: var(--fontfamily);
    font-weight: 400;
}
.save-pay
{
    color: #11173A;
    font-size: 12px;
    opacity: 0.5;
    line-height:20px;
    cursor: pointer;
}
.new_wallet
{
    color: #11173A;
    font-size: 12px;
    line-height:20px;
    cursor: pointer;
}

.locationopen
{

    flex-wrap: wrap;
    width:500px;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    height:auto;
    position: absolute;
    box-sizing: border-box;
    background-color: var(--white);
 
}

.locationopened
{

    flex-wrap: wrap;
    width:560px;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    height:auto;
    position: absolute;
    box-sizing: border-box;
    background-color: var(--white);
    display: grid;
}

.locationconfirm
{
    flex-wrap: wrap;
    width:400px;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    height:auto;
    position: absolute;
    box-sizing: border-box;
    background-color: var(--white);
   
}

.editaddress
{
    font-size: 10px;
   font-style: normal;
    font-weight: 500;
  line-height: normal;

}

.confirm_address
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 220px;
}

.deleted_itemaddress
{
    
    display: flex;
    justify-content: center;
    grid-column-gap: 26px;
    padding-top: 40px;
}
.booking_itemaddress
{
    display: flex;
    justify-content: space-between;
    grid-column-gap: 4%;
    margin-bottom: 10px;
}



.step-time
{
    color: #4D4D4D;
    font-family: var(--fontfamily);
    font-size: 12px;
    font-weight: 500;
     line-height: normal;
}

.editdata
{
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
  padding: 8px 10px;
  box-sizing: border-box;
}

.deleteaddress
{
     display: flex;
     grid-column-gap: 6px;
     align-items: center;
     cursor: pointer;
}

.currentdata-addressed
{
    font-size: 14px;
    font-family: var(--fontfamily);
    font-weight: 400;
    margin:-10px 0px 4px;
    width:300px;
    
}

.close-icon{
     transform: scale(0.4);
     border: 1px solid var(--black);
     border-radius: 50%;
     padding: 2px;
     cursor: pointer;
}

.ratings-data
{
    display: grid;
    grid-row-gap: 10px;
}

.current-location
{
    font-size: 12px;
    font-family: var(--fontfamily);
    font-weight: 400;
    
}
.current-addressdata{
           
     font-size: 14px;
     font-family: var(--fontfamily);
     font-weight: 400;
     padding-top: 10px;
}


.current-addressed
{
     font-size: 14px;
     font-family: var(--fontfamily);
     font-weight: 400;
     margin:-10px 0px 4px
}
.saved_border
{
    position: relative;
    top:-4px;
}

.locationIcons
{
    display: flex;  
    align-items: center;
    grid-column-gap: 10px; 
    padding-top: 4px;
}

.mylocation
{
     display: flex;
     align-items: center;
}

.locationname{
  position: relative;
}

.address_selection
{
    padding: 8px 0px;
}
.address_locations
    {
        padding: 0px 10px 10px ;
    }
    .addressdata
    {
        margin-bottom: -14px;
    
    }
    .addressdatebutton
    {
        margin: 6px 0px;
    }

    .mylocations
    {
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        align-items: center;
    }

  .mycurrentlocation
  {
    border-radius: 6px;
    border: 1px solid #CECECE;
    background: #FAFAFA;
    padding: 20px;
    cursor: pointer;
  } 
  

  .current_address
  {
     display: flex;
     justify-content: space-between;
     align-items: center;
     width: 100%;
  }
  .my_current_location
  {
    display: flex;
    grid-column-gap: 16px;
    align-items: center;
  }
  .custom_button
  {
    display: flex;
    grid-column-gap: 20px;
  }

.my_locationIcon
{
   display: flex;
   align-items: center;
}

.locationaddress
{
    margin-top: 20px;
}

.location-confirm
{
    display: flex;
    justify-content: space-between;
}
  .dragballoon
  {
     font-family: var(--fontfamily);
     font-size: 18px;
     font-weight: 600;
  }

     .locationIcondata
     {
         display: flex;
         align-items: center;
         margin-top: 14px;
         grid-column-gap: 10px;
         cursor: pointer;
     }
     .locationIcondatas
     {
        display: flex;
        align-items: center;
        grid-column-gap: 10px;
        cursor: pointer;
     }

    .keyboardarrowleft
    {
   
            cursor: pointer;
           background: transparent;
           border:1px solid #d7d4d4;
             border-radius: 50%;
             display: flex;
             align-items: center;
             padding: 8px;
             justify-content: center;
             box-sizing: border-box;
            
    }

   .keyboardarrowleft:hover
   {
     background-color: #e2dcdc;

   }
 
      .activeTab
      {
          outline: none;
          background: none;
          border: none;
      }

   .tabcontext
   {
        display: flex;
   }
.MuiTabs-flexContainer 
{
    display: flex !important;
    grid-column-gap: 3vw;
}
    
.saved-location
{
     display: grid;
     grid-row-gap: 20px;
}

.morevertIcon
{
     cursor: pointer;
     position: relative;
     top:2px;
}

.saved_currentlocation
{
    display: grid;
    grid-row-gap: 4px;
    padding: 6px 0px 8px;
}

.close-cancelled
{
    display: flex;
    grid-column-gap: 10px;
}

.thanking
{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width:100%;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        height:100%;
        align-items: center;
        position: absolute;
        box-sizing: border-box;
        flex-direction: column;
        grid-row-gap: 30px;
 
}

.checkCircleIcon
{
   transform: scale(5);
   color: var(--primary);
}
.ErrorIcon
{
    transform: scale(5);
    color: red;
}

.thank_title{
    font-size: 36px;
    font-weight: 700;
    font-family: var(--fontfamily);
}

.payment_done
{
   
    font-weight: 500;
    font-family: var(--fontfamily);
    font-size: 24px;
}
.close-icondata
{
     background-color: red;
     display: flex;
     align-items: center;
     border-radius: 50%;
     color: #ffffff;
     padding: 0px 9px;
     transform: scale(0.8);

}

.done-icondata
{
    background-color: var(--primary);
     display: flex;
     align-items: center;
     border-radius: 50%;
     color: #ffffff;
     padding: 0px 9px;
     transform: scale(0.8);
}

.service-cancelled
{
    margin-bottom: 6px;
}

.service-time
{
    color: rgba(17, 23, 58, 0.50);
    font-family: var(--fontfamily);
    font-size: 10px;
    padding-top:4px ;
}




.css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected
{
    color: var(--primary)!important;
}

.MuiTabs-indicator {
    background-color: var(--primary) !important;
  }

.Mui-selected
{
    color: var(--primary)!important;
    font-family: var(--fontfamily)!important ;
}
.css-imwso6-MuiBottomNavigationAction-label 
{
    font-family: var(--fontfamily)!important ;
    font-size: 12px !important;
}
.MuiPaginationItem-page.Mui-selected
{
    color: var(--white)!important;
}

.explore_titlewide{
    color: #717171;
    text-align: center;
    font-family: var(--fontfamily);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 30px;

  }
  .bookingyet{
    text-align: center;
   
  }

  .bookinglogo
  {
     text-align: center;
     transform: scale(0.8);
     height:190px;
  }

  
  .upcomingdata
  {
    display: flex;
    grid-column-gap: 10px;
 
  }
  .primaryborder
  {
    width: 100%;
    height: 5px;
    flex-shrink: 0;
    background: var(--primary);
  }

 

  .finished
  {
   
    color: #FF6813;
    text-align: center;
    font-family: var(--fontfamily);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 10px;
    border-radius: 25px;
    background-color: #FFFCE3;
    height: 10px;
    padding: 6px 8px;
    
  }
  .booking_details
  {
     display: flex;
     justify-content: space-between;
     padding-bottom:20px ;
  }
  .booking_detailsdata
  {
    display: flex;
    justify-content: space-between;
  }

  .bookingid
  {
    display: flex;
    flex-direction: column;
    grid-row-gap: 6px;
  }

  .bookingbutton
  {
          display: flex;
          justify-content: center;
  }

  .booking_title
  {
    color:  #1A1E24;
    font-family: var(--fontfamily);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
  .booking_titledata
  {
    color:  #1A1E24;
    font-family: var(--fontfamily);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 10px;
  }

  .booking_name
  {
    font-weight: 700;
    font-family: var(--fontfamily);
    font-size: 14px;
    font-style: normal;
    line-height: normal;
  }



  .booking_time
  {
    color: rgba(26, 30, 36, 0.50);
    font-family: var(--fontfamily);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
.review_rating
{  
    color:var(--primary);
    font-family: var(--fontfamily);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}
.review_rating:hover
{
    text-decoration: none;
}

  .booking_timeid
  {
    color: rgba(26, 30, 36, 0.50);
    font-family: var(--fontfamily);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .tracker
  {
       display: flex;
       grid-column-gap: 10px;
  }
  
  .assigned
  {
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding-inline: 6px;
    
  }
  .booking_view
  {
     display: grid;
     grid-row-gap: 20px;
  }

  .step_label {
    display: flex;
    flex-direction: column;
  }
  
  .lower-section {
    display: flex;
    padding-top: 10px;
    justify-content: flex-end;
  }
  
  .booked{

    color: #39B64A;
    font-family: var(--fontfamily);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
  }

   .placed{

    color: #ffcc00;
    font-family: var(--fontfamily);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
   }

  .cancelled
  {
    color: #D53A39;
    text-align: center;
    font-family: var(--fontfamily);
    font-size: 12px;
    font-style: normal;
    font-weight: 600;

  }
  .booking_container
  {
      margin-bottom: 20px;
  }

  .acceptdetail
  {
     display: flex;
  }

  .upcomingshowdata
  {
    color: #57339F;
    font-family: var(--fontfamily);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-bottom: 12px;
  }

  .MuiButtonBase-root
  {
        max-width: none !important;
        min-width: none !important;
  }
  .section-container
  {
    padding: 0px 3%;
  }
  .section-containers
  {
    padding: 8px 3% 0px;
  }

  .schedule_title
  {
    display: flex;
    align-items: center;
   font-size: 18px;
   line-height: 17px;
   font-weight: 600;
   font-family: var(--fontfamily);
  }
  .schedule-online {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%; 
  }

  .schedule-online h1 {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    font-family: var(--fontfamily);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
 
  .schedule-booking
   {
    display: flex;
    justify-content: space-between;
    width:100%;
    align-items: center;
   }

   .schedule-online
   {
     display: flex;
     align-items: center;
   
   }

   .tracking_service
   {
       display: flex;
       justify-content: space-between;
       align-items: center;
   }

   .explore_title{

    color:var(--black);
    text-align: center;
    font-family: var(--fontfamily);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}



.experiencedata
{
    cursor: pointer;
}

.booking_detaildata
{
       
  display: grid;
  grid-row-gap: 10px;
}
.booking_address
{
  color: rgba(26, 30, 36, 0.40);
}

.fmdgood
{
  opacity: 0.4;
}

.packagelogo
{
   display: flex;
   grid-column-gap: 10px;
}

.booking_service
  {
    
    font-family: var(--fontfamily);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .cancelled_service
  {
   
  }

  .accounttype
  {
      color: var(--black);
      font-family: var(--fontfamily);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
  }
  .experience-servicedata
  {
    padding: 0px 8px 14px;
  }

  
  .schedule-payment
  {
     display: flex;
     justify-content: space-between;
     width:100%;
  }


  .formattiming
  {
      text-align: center;
      font-family: var(--fontfamily);
      font-size: 28px;
      font-weight: 700;
      line-height: normal;
      color:#ffffff;
  }
  .paymentpaid
  {
        display: grid;
        grid-row-gap:10px;
        line-height: normal;
        font-family: var(--fontfamily);
  }
  .lottie{
       width:"100px"
  }

  .payment_type
  {
      display: flex;
      justify-content: space-between;
  }
  .payment_typed
  {
    display: flex;
    justify-content:space-between;
    padding-bottom: 6px;
   
  }

  .booking_request
  {
      color: #ffffff;
      text-align: center;
      font-family: var(--fontfamily);
      font-size: 16px;
      font-weight: 500;
      line-height: normal;
      position: relative;
      top:-20px;
      
  }

  .similar_services
{
    padding-inline:12px;
}

.similar_viewservices
{
    margin-inline: 12px;
}
.similar_billservices
{
    margin-right: 12px;
}

 /*
   .booking_successes
   {
      background-color: #1B8C41;
   }
*/
.reschedule-payment
{
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
}

.reschedule-paymentdata 
{
    display: flex;
    justify-content: space-between;
    grid-column-gap: 8px;
}

@media (max-width:1100px)
{
    .reschedule-paymentdata 
    {
        display: grid;
        grid-template-columns:1fr ;
        grid-template-rows: 1fr 1fr;
        grid-row-gap: 4px;
    }
}

.paymentfull
{
  font-family: var(--fontfamily);
  font-weight: 500;
  font-size: 12px;
}
.roundimage
{
    border-radius: 6px;
}
.address_detail
{
    color:black;
    font-family: var(--fontfamily);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media (max-width:1200px)  {

    .MuiTabs-flexContainer 
    {
          display: flex !important;
          grid-column-gap: 0px;
    }
}

@media (max-width:990px)
{
     .service_available
     {
        width:32vw ;
     }
}

@media (max-width:768px)
{

         .booking_timeid
         {
            font-size: 12px;
         }
         .dragballoon
         {
            font-family: var(--fontfamily);
            font-size: 14px;
            font-weight: 600;
            padding:0px 0px 10px 22px;
         }
         .addressdate 
         {
            margin-bottom: 10px;
         }

         .dragfooter
         {
            display: none;
         }
         .dragheader
         {
            padding: 10px 0px;
         }
         .dragbody
         {
            padding: 0px;
         }
         .lower-section {
           display: flex;
           justify-content: flex-end;
         }
         .schedule-online h1 
         {
            font-size: 16px;
         }
         .label_title
         {
            font-size: 14px;
         }
     .p-6
     {
         padding:0px 6px; 
     }
     .sectiondeals
     {
        padding: 6px;
     }


.similarcontainer
{
    padding-left: 14px !important;
    padding-right: 14px !important;
}


.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root
{
      min-height: 0px !important;
}

     .p-20
     {
        padding-top: 20px;
     }
     .pt-20
     {
        padding-block: 20px;
     }

     .m-10
     {
        margin-top: 10px;
    }
    .css-1hv8oq8-MuiStepLabel-label.Mui-active {
     
        font-weight: 600 !important;
        font-family: var(--fontfamily);
        font-size: 14px;
        line-height: 20px !important ;
        display: grid;
        grid-row-gap: 4px;
       
    }
    .checkCircleIcon
{
   transform: scale(4);
   color: var(--primary);
}

.ErrorIcon
{
    transform: scale(4);
    color:red;
}
.thank_title
{
    font-size: 28px;
}
.thanking
{
    display: flex;
    flex-direction: column;
    grid-row-gap: 12px;
}
.payment_done
{
    font-size: 20px;
    position: relative;
    top:-16px;
}
    
    
   
    .timing
    {
        font-size: 14px;
        padding: 6px;
    }
    .additional_title
    {
        font-size: 14px;
        font-weight: 500;
    }
    .saved-location
    {
         display: grid;
         grid-row-gap: 0px;
    }
    .additional_titlehome
    {
           font-size: 14px;
         font-family: var(--fontfamily);
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-right: 4px;
    }
    .address_selection
    {
        padding: 8px 0px;
    }
    .mylocation
    {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .current-addressdata{
           
        font-size: 12px;
   }

    .keyboard
    {
         cursor: pointer;
    }
    .locationIcon
    {
       margin: 0px 0px;
     
    }
    .locationIcons
    {
        display: flex;
        
        align-items: center;
        grid-column-gap: 10px; 
    }


    .mylocation
    {
         display: flex;
         align-items: center;
         margin-top: -14px;
    }

    .locationname{
      position: relative;
      top:-6px;
    }
    
    .direction
    {
         text-transform: uppercase;
         font-weight: 500;
         font-family: var(--fontfamily);
         font-size: 14px;

    }
    .addressdata
    {
        margin-bottom: -10px;
    } 
    .current-addressed
{
     font-size: 12px;
}
    
    .totalservice
    {
         display: flex;
         justify-content: space-between;
    }
   .servicetitle
   {
     font-weight: 700;
     line-height: normal;
     margin: -10px 0px;
   }

   .additional_wallet
   {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width:100%;
   }


    .address_locations
    {
        padding: 0px 10px 10px ;
    }

    .currencyservice
    {
         display: flex;
         align-items: center;
    }
    .serviceaddress
    {
        margin: -10px 0px -14px;
        line-height: normal;
    }
    .service_delivery
    {
        align-items: center;
        display: flex;
    }

    .paymentOptions
    {
        margin-bottom: -45px;
    }

    .validUpi
    {
        font-family: var(--fontfamily);
        color: red;
        font-weight: 500;
    }

    .containerbottom
    {
         position: relative;
         height: 100%;
    }

    .containerpayment
    {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width:100%;
        top:50%;
        left:50%;
        transform:translate(-50%,-60%);
        height:100%;
        align-items: center;
        position: absolute;
        box-sizing: border-box;
        
    }

 
    .leftpayment
    {
        text-align: center;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: var(--black);
        padding: 2px 10px;
    }

    .approvepayment
    {
        color: var(--black);
        text-align: center;
        font-family: var(--fontfamily);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
  
 
     .canceldata
     {
        text-align: center;
        padding-top: 20px;
     }
     .circulardata{
        text-align: center;
     }

    .cancelpayment
    {

        color: rgba(0, 0, 0, 0.50);
        text-align: center;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
    }
    .cancelpayment:hover
    {
        cursor: pointer;
    }

    .back-button
    {
        color:var(--black);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        position: fixed;
        bottom: 70px;
    }  
    
    .circularprogressdata
    {
        display: grid;
        grid-row-gap: 20px;
    }
    
    .css-glg1l6-JoyCircularProgress-progress
    {
        animation: none !important;
        -webkit-animation: none !important;
    }

   

    .close-icondata
    {   
         padding: 0px 7px;
    }

    .done-icondata
    {
        padding: 0px 7px;
    }
    .reschedule-payment
    {
       display: grid;
       grid-template-columns:  1fr;
       grid-template-rows: 1fr 1fr;

    }
    .reschedule-paymentdata
    {
        display: flex;
        flex-direction: column;
        grid-row-gap: 10px;
    }
  
 
    .accounttype
    {
      
        font-size: 14px;
       
    }

    .labelcard
    {
           display: grid;
           grid-row-gap: 10px;
    }
  
      .save_card
      {
        padding-top: 10px;
      }

  
      .quantitynumber
      {
          margin-top: -2px;
          font-size: 14px;
      }

        
      .quantitynumbers
      {
          font-size: 14px;
      }



   .keyboardarrowleft
   {
      cursor: pointer;
           background: transparent;
           border:1px solid #d7d4d4;
             border-radius: 50%;
             display: flex;
             align-items: center;
             padding: 0px;
             justify-content: center;
             box-sizing: border-box;
   }
   .address_locationdate
   {
     padding: 0px 4px 6px;
   }

.MuiTabs-flexContainer 
{
    display: flex !important;
    grid-column-gap: 0px;
}
   .tracking-slider {
    display: grid;
    height: 380px;
    border-radius: 5px;
    overflow: hidden;
    align-items: center;
  }
  
  .tracking-step {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #C0C0C0;
    border: 1px solid #C0C0C0;
    box-sizing: border-box;
  }
  
  .step-label {
    margin-left: 10px;
    width:100%;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-family: var(--fontfamily);
    color: #C0C0C0;
    box-sizing: border-box;
    top:-3px;
    position: relative;
  }
  
  .vertical-line {
    position: absolute;
    height:45px;
    width: 2px;
    left:14px;
    background-color: #C0C0C0;
    
  }
  
  .tracking-step.active .circle {
    background-color: var(--primary);
    color: var(--white);
  }
  
  .tracking-step.active .step-label {
    color: var(--black);
  }
  
  .tracking-progress {
    height: 100%;
    background-color: #ff6600;
    transition: width 0.3s ease;
  }

  .vertical-length
  {
     display: grid;
     align-items: center;
     grid-row-gap: 9px;
  }
  
   .booking_request
   {
    font-size: 14px;
   }
   .formattiming
   {
     font-size: 24px;
   }
   .booked_successed
{
    position: relative;
    top:10px;
}
 
   .tabcontext
   {
     
        display: flex;
        width:100%;
     justify-content: center;

   }
    .service_available
    {
        width:100%;
    }
  

 
   .addressdatalocation
   {
      display: flex;
       grid-column-gap: 4%;
       

   }

}
.addressdatalocation
{
   display: flex;
    grid-column-gap: 4%;
    

}
@media (max-width:600px)
{
    .service_available
    {
        width:100%;
    }
}

@media (max-width:480px)
{
    .timing{
        font-size: 12px;
    }
}
.keyboardup
{
     position: relative;
     top:3px;
     cursor: pointer;
}

/* Schedule */





/* Bottom Naviagtion */
 
@media (min-width:768px)
{
    .bottomNavigation
    {
        display: none;
    }
}

.bottomNavigation
{
    padding-top: 1px;
   
}
/* Bottom Navigation */


/* Footer */

.bottom{
    margin-bottom: 40px !important;
}

.footer_title
{
   
    text-decoration: none;
    font-family: var(--fontfamily);
    font-size: 12px;
    font-weight: 500;
    color: #373737;
    cursor: pointer;
}
.logging_title
{
    font-family: var(--fontfamily);
    font-size: 12px;
    font-weight: 500;
}

.footer_pagargraph
{
    display: flex;
   grid-column-gap: 24px;
   box-sizing: border-box;
}
.footer_address
{
  
    box-shadow: var(--box-shadow);
    background-color: var(--white);
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
}
.footer_right
{
    width: 100%;
    display: flex;
     align-items: center;
     justify-content: space-between;
}

.footer_resource
{
    position: fixed;
    bottom: 0px;
    z-index: 100;
    width: 100%;
  
}
.footer_link
{
    color: rgb(88, 88, 88);
    text-decoration: none;
}

@media (max-width:768px)
{
    .footer_resource
    {
        display: none;
    }
    .bottom{
        margin-bottom:0px !important;
    }

   
}

/* Footer */

.mb:last-child
{
    margin-bottom: 60px;
}
.mb_bookmark:last-child
{
    margin-bottom: 40px;
}

.global_box_shadow
{
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.08) !important;
    border-radius: 4px !important;
    border: 1px solid #E9E9E9 !important;
    background: #FFF !important;
}

.global_box_shadowskeleton
{
    box-shadow: none !important;
    border-radius: 10px !important;
    border: 1px solid #E9E9E9 !important;
    background: #FFF !important;
}
.global_box_shadows
{
    box-shadow: none !important;
    border-radius: 0px !important;
    border: 1px solid #EEE !important;
    background: #FFF !important;
}
.border_bottom {
    border-bottom: none !important;
}
.border_top {
    border-top: none !important;
}
.apply_border_top {
    border-top: 1px solid #aba7a7;
    margin-top: 20px;
    padding-top: 14px;
}

/* Landing Page */


.headers
{
    background-color: var(--white);
    height:80px;
    display: flex;
    top: 0;
    width:100%;
    z-index:999;
    align-items: center;
    box-sizing: border-box;
}
.home
{
     text-decoration: none !important;
}

.section
{
 padding: 80px 0px 80px;
}

.primary_buttons
 {
   font-size: 1.125rem !important;
   line-height: 1.75rem !important; 
   padding: 14px 22px !important;
   border-radius: 8px !important;
   letter-spacing: normal !important;
    outline: none;
    border: 1px solid var(--primary);
    color:#000000;
    background-color: var(--primary);
    cursor: pointer;
     box-sizing: border-box;
     position: relative;
     font-weight: 600;
 }

 .hero_titles{
    font-size: 3.4rem;
    font-weight: 900;
    line-height: 4.4rem;
    margin: 15px 0 15px;
 }
 .secondary_titles{

    font-family:var(--fontfamily);
    font-size:22px;
    font-weight:500;
    line-height:28px;
 
  }

.homehover:hover {

   color:var(--primary);
   text-decoration: underline solid var(--primary) !important; 
   text-underline-offset: 3px;
 }

.uxhover
{
   color:var(--secondary);
}
.uxhoverdata
{
   color:var(--secondary);
}

.uxhoverdata:hover
{
   color:var(--secondary);
   text-decoration: underline solid var(--secondary) !important; 
   text-underline-offset: 4px;
}

.uxhover:hover
{
   color:var(--secondary);
   text-decoration: underline solid var(--secondary) !important; 
   text-underline-offset: 2px;
}

.uihover:hover
{
   color:#fafafa;
   text-decoration: underline solid #fafafa !important; 
   text-underline-offset: 2px;
}

.uxhover:hover
{
   color:var(--secondary);
   text-decoration: underline solid var(--secondary) !important; 
   text-underline-offset: 2px;
}


.text-center
{
   text-align: center !important;
}

.premium
{
   background: #FFFFFF;
   border: 2px solid #FFFFFF;
   box-sizing: border-box;
   box-shadow: 0px 12px 60px 7px rgba(0, 0, 0, 0.08);
   border-radius: 10px;
   margin:auto 20px;
   position:relative;
   height:100%;
   padding: 10px 20px 20px;
}

.badge 
{
   background-color: var(--primary);
   font-family: var(--font-family);
   font-style: normal;
   font-weight: 500;
   font-size: 12px;
   line-height: auto;
   text-align: center;
   letter-spacing: 0.02em;
   text-transform: capitalize;
   color: #ffffff;
   float: right;
   position:absolute;
   right:-17px;
   padding:8px 12px;
   border-radius:10px 0 0 10px;
   z-index: 2;
 
  
}

.signup
{
    border: 1px solid rgba(74, 29, 201, 1);
    border-radius: 6px;
    padding:10px 18px;
    color: rgba(74, 29, 201, 1);
}

.dynamic
{
   padding:30px 0px 20px;
   position:relative;
}
.dynamic_label 
{
      color: #000000;
      font-family: var(--font-family);
      font-size: 32px;
      font-weight: 700;
      line-height: 18px; 

}
.item-list
{
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    margin: 0px !important;
}

.keyboardArrow
{
   opacity: 0.4;
   cursor: pointer;
}
.border-radius
{
   border-radius: 10px;
}
.border-bottom
{
   border-bottom: 1px solid rgb(221, 216, 216);
}

.developcarousel div
{

   margin-right: 20px;
}


.footer_title
{
    color:var(--secondry);
    text-decoration: none;
    line-height:normal;
}
.footers_pagargraph
{
  
    font-family: var(--font-family);
    display: grid;
    grid-row-gap: 6px;
    line-height: 10px;
}
.footer_primary_title
{
    font-family: var(--font-family);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.footer_address
{
    line-height: 24px;
    padding: 8px 0px 6px;
}
.footer_font
{
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-family);
}
.footer_fonts
{
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-family);
}
.phone_icon
{
    display: flex;
    color:var(--secondry);
    text-decoration: none;
    line-height:normal;
    grid-column-gap: 2px;
    align-items: center;

}
.contact_radifix
{
    color:var(--secondry);
    text-decoration: none;
    font-size: 16px;
}
.border_top
{

   padding: 20px;
   border-top: 1px solid #f1f1f1;

}

.footer_link
{
    color: black;
    text-decoration: none;
}
.footer_condition
{
   display: flex;
   grid-column-gap: 24px;
   box-sizing: border-box;
   flex-wrap: wrap;
   grid-row-gap: 8px;
  
}

.footer_terms
{
   display: flex;
   justify-content: space-between;
   align-items: center;
   grid-column-gap: 20px;
   grid-row-gap: 8px;
   flex-wrap: wrap;
}

.black{
   color: #000000 !important;
}
.social_media
{
   display: flex;
   grid-column-gap: 20px;
   flex-wrap: wrap;
   grid-row-gap: 10px;
   padding-top: 14px;
  
}

.image_container
{
   max-width: 100%;
   height: calc(100vh - 260px);
   background-color: rgba(239, 236, 255, 1);
   border-radius: 22px;
}
.navlink
{

   display: flex;
   grid-column-gap: 30px;
   align-items: center;
   font-weight: 500;
  font-size: 14px;
} 

@media (max-width:768px)
{
   .footer_condition
{
   display: flex;
   grid-column-gap: 24px;
   box-sizing: border-box;
   flex-wrap: wrap;
   grid-row-gap: 0px;
  
}
.navlink {
    display: none;
}
   
}


/* Landing Page */